import { Component, HostListener, Inject, AfterViewChecked, Injector, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService, JQueryService, CanAccessService, NotificationService, SetSbaProductConfig } from '@rubicon/utils_custom';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { environment } from '@env-affiliate-base/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { debounceTime, take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import 'libs/utils/src/lib/interfaces/number';//do not remove inmported for adding toRound in number


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewChecked, OnInit, OnDestroy  {

  storeSubscription: Subscription;
  client_name: any;
  openUVModalSubs: Subscription;
  constructor(translate: TranslateService, 
    private common: CommonService,
    @Inject(DOCUMENT) private document: Document, 
    @Inject("environment") private environment, 
    private jQuery: JQueryService,
    private location: LocationStrategy,
    // private gtmService: GoogleTagManagerService,
    private injector: Injector,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private store: Store<any>,
    private canAccessService: CanAccessService,
    private notificationService: NotificationService
    ) {
    //  this.document.getElementById('appFavicon').setAttribute('href', environment.faviconPath);
    /**
      * Update title and metadata
      */
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.titleService.setTitle(this.getMetadata(item.url).title);
        this.meta.updateTag({ name: 'description', content: this.getMetadata(item.url).meta });

        /**refresh notification on route change */
        this.notificationService.updateNotificationSub.next(true);
      }
    });
    /**
     * Tracking via google tag manager
     */
    if (environment.GTM_CODE) {
      // this.gtmService = <GoogleTagManagerService>this.injector.get(GoogleTagManagerService);
      // this.router.events.forEach(item => {
      //   if (item instanceof NavigationEnd) {
      //     const gtmTag = {
      //       event: 'page',
      //       pageName: item.url,
      //       data: this.getMetadata(item.url).meta
      //     };
      //     this.gtmService.pushTag(gtmTag);
      //   }
      // });
    }

    this.common.routerEvent.subscribe((val) => {
      if (val instanceof NavigationEnd)
        window.scroll(0, 0);
    });
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      this.common.isBackButtonClicked=true;
      return false;
    });
    translate.setDefaultLang(environment.appLang);
    this.addMouseListener();
  }

  /**
   * 
   * @param url Add title and meta data as per route
   */
  getMetadata(url){
    let title = this.client_name === 'BFP' ? "Business Funding Portal" : 'CPA Business Funding Portal';
    
    let data = {
      title: title,
      meta: 'Sign into your account on the CPA Business Funding Portal. Available to verified CPA and accounting firms to help their small business clients secure financing.'
    };

    switch(url){
      case '/loan-info':
      case '/loan-info?register=new':
        data['title'] = 'PPP Loan Information | PPP Forgiveness';
        data['meta'] = 'Provide your PPP loan information to get all Loan Forgiveness documents automatically created and maximize your PPP loan forgiveness with less paperwork.';
        break;
      case '/about-you':
          data['title'] = 'About Your Business | PPP Forgiveness';
          data['meta'] = 'Provide your business information to get all Loan Forgiveness documents automatically created and maximize your PPP loan forgiveness with less paperwork. ';
        break;
      case '/question-answer':
          data['title'] = 'Forgiveness Application Question | PPP Forgiveness';
          data['meta'] = 'Answer the questions below to determine if your business qualifies for EZ Forgiveness Application';
        break;
      case '/payroll-schedule':
          data['title'] = 'Payroll Information | PPP Forgiveness';
          data['meta'] = 'Provide your business payroll information to get all Loan Forgiveness documents automatically created and maximize your PPP loan forgiveness with less paperwork.';
        break;
      case '/eligible-forgiveness':
          data['title'] = 'Forgiveness Eligibility Calculations | PPP Forgiveness';
          data['meta'] = 'Provide your Non-Payroll Costs to complete the Forgiveness Amount Calculation.';
        break;
      case '/documents':
          data['title'] = 'Upload Documents | PPP Forgiveness';
          data['meta'] = ' Upload the required documents to get all Loan Forgiveness documents automatically created and maximize your PPP loan forgiveness with less paperwork.';
        break;
      case '/take-it-away':
          data['title'] = 'Download Application Packet | PPP Forgiveness ';
          data['meta'] = 'Download your PPP loan forgiveness application packet to send to your lender.';
        break;
        case '/ppp2/eligibility':
          data['title'] = `${title} | Loan Eligibility`;
          data['meta'] = 'The Paycheck Protection Program has strict eligibility requirements that the government has established. You must certify the loan amount that your business is eligible for based on your average payroll expenses.';
          break;
        case '/ppp2/eligibility-question':
           data['title'] = `${title} | Loan Eligibility | Eligibility Question`;
           data['meta'] = 'We need you to provide a little more information so we can use the right eligibility checks for your business. Answering these questions as accurately as possible speeds up your processing time. ';
          break;
        case '/ppp2/payroll-details':
           data['title'] = `${title} | Loan Eligibility | Payroll Details`;
           data['meta'] = 'The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria and​ who need the funding to keep their employees on payroll during the COVID-19 pandemic. The details you​ provide here are used to verify your eligibility for the program according to government rules.​';
          break;
        case '/ppp2/employee-details':
           data['title'] = `${title} | Loan Eligibility | Payroll Information`;
           data['meta'] = "The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria. In general​ this requires companies to have fewer than 500 employees or meet the SBA's minimum size standards​ for their particular industry. In addition, wages paid to 1099 contractors are not eligible for this program.​";
          break;
        case '/ppp2/eligibility-values':
           data['title'] = `${title} | Loan Eligibility | Enter Eligibility Values `;
           data['meta'] = 'The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria and​ who need the funding to keep their employees on payroll during the COVID-19 pandemic. The details you​ provide here are used to verify your eligibility for the program according to government rules.';
          break;
        case '/ppp2/offer-details':
           data['title'] = `${title} | Offer Details`;
           data['meta'] = 'Offer Details';
          break;
        case '/ppp2/business-info':
           data['title'] = `${title} | Business Information`;
           data['meta'] = 'This information is required by the government for your Paycheck Protection Program application. It is used to fill out the SBA Form 2483.';
          break;
        case '/ppp2/business-info-2':
           data['title'] = `${title} | Business Information`;
           data['meta'] = 'This information is required by the government for your Paycheck Protection Program application. It is used to fill out the SBA Form 2483.';
          break;
        case '/ppp2/owner-info':
           data['title'] = `${title} | Ownership Information`;
           data['meta'] = 'This information is required by the government for your Paycheck Protection Program application. It is used to fill out the SBA Form 2483.';
          break;
        case '/ppp2/government-declaration':
           data['title'] = `${title} | Gov't Declarations`;
           data['meta'] = 'According to government rules, borrowers under the Paycheck Protection Program must certify certain declarations in order to ensure they are eligible for a loan from the program.';
          break;
        case '/ppp2/government-declaration-step2':
           data['title'] = `${title} | Gov't Declarations`;
           data['meta'] = 'According to government rules, borrowers under the Paycheck Protection Program must certify certain declarations in order to ensure they are eligible for a loan from the program.';
          break;
        case '/ppp2/esign':
           data['title'] = `${title} | Review and Sign the Application​`;
           data['meta'] = 'Please review your application before signing to ensure all information entered is correct.  You can then complete the signatures required through our secure e-signature process.'
          break;
        case '/ppp2/documents/upload':
           data['title'] = `${title} | Upload documents`;
           data['meta'] = 'Please upload the following documents that are required for verification of business information under the Paycheck Protection Program.';
          break;
        case '/term-loan/business-structure':
           data['title'] = "Business Structure | Business Term Loan";
           data['meta'] = 'Select your business structure to see what term loan you qualify for';
          break;
        case '/term-loan/loan-details':
           data['title'] = "Loan Details | Business Term Loan";
           data['meta'] = 'Select the purpose of your loan and indicate your total loan requirement to proceed to the next step of the application.';
          break;
        case '/term-loan/owner-details':
           data['title'] = "Owner Details | Business Term Loan";
           data['meta'] = 'Fill in the details of all the owners with 20%+ stake in your company in order for the application to be approved.';
          break;
        case '/term-loan/business-info':
           data['title'] = "Business Info | Business Term Loan";
           data['meta'] = 'Fill in the information of your client’s business to proceed to the next step of the application.';
          break;
        case '/term-loan/document-management/upload':
           data['title'] = "Document Management | Business Term Loan";
           data['meta'] = 'Upload all the required documents for your application to be approved. To fast track your application, upload the optional documents also.';
          break;
        case '/term-loan/application-view':
           data['title'] = "Application View | Business Term Loan";
           data['meta'] = 'Review your application once more to ensure all the information is correct before submitting.';
          break;
        case '/signup':
           data['title'] = `Registration | ${title}`;
           data['meta'] = 'Fill in the information of your CPA Firm to sign up for the portal.';
          break;
        case '/signin':
           data['title'] = `Sign In | ${title}`;
           data['meta'] = `Sign into your account on the ${title}. Available to verified CPA and accounting firms to help their small business clients secure financing.`;
          break;
        case '/faqs':
           data['title'] = `FAQs | ${title}`;
           data['meta'] = `Here is the ${title} FAQs page with answers to the most frequently asked questions.`;
          break;
        case '/resource-hub':
           data['title'] = `Resource Hub | ${title}`;
           data['meta'] = `Here is the resource hub of all the helpful content about the ${title} to better help your firm’s clients.`;
          break;
        case '/privacy-policy':
           data['title'] = `Privacy Policy | ${title} `;
           data['meta'] = `Review the privacy policy of the ${title}.`;
          break;
        case '/terms-of-use':
           data['title'] = `Terms of Use | ${title}`;
           data['meta'] = `Review the Terms of Use of the ${title}.`;
          break;
        case '/dashboard':
           data['title'] = `Dashboard | ${title}`;
           data['meta'] = `Sign into the ${title} Dashboard here.`;
          break;
        case '/dashboard?signin=true':
           data['title'] = `Dashboard | ${title}`;
           data['meta'] = `Sign into the ${title} Dashboard here.`;
          break;
        case '/manage-user':
           data['title'] = `Manage Users | ${title}`;
           data['meta'] = 'Manage all the users from your firm here by assigning everyone a role so you can all work efficiently.';
          break;
        case '/my-clients':
           data['title'] = `My Clients | ${title}`;
           data['meta'] = 'Manage all the clients here.';
          break;
        case '/upload-customer':
           data['title'] = `Upload Customer | ${title}`;
           data['meta'] = 'Upload all your new customers here in order to begin their financing application.';
          break;
        case '/my-plan':
           data['title'] = `My Plan | ${title} `;
           data['meta'] = 'Select the subscription plan that works best for your firm here.';
          break;
        case '/my-plan/commission-report':
           data['title'] = `Commission Report | ${title}`;
           data['meta'] = 'Get real time updates on your firm’s performance on the commission report.';
          break;
        case '/my-plan/funnel-report':
           data['title'] = `Funnel Report | ${title}`;
           data['meta'] = 'Get real time updates on your clients’ financing journey on the funnel report.';
          break;
        case '/dashboard/funnel-report':
           data['title'] = `Funnel Report | ${title}`;
           data['meta'] = 'Get real time updates on your clients’ financing journey on the funnel report.';
          break;
        case '/financing-inquiry':
           data['title'] = `Financing Products Inquiry | ${title}`;
           data['meta'] = `Contact the ${title} team about financing products that can be made available to your clients.`;
          break;
        case '/ertc-loan-inquiry':
            data['title'] = `Financing Products Inquiry | ${title}`;
            data['meta'] = `Contact the ${title} team about financing products that can be made available to your clients.`;
          break;
        case '/sba-loan-inquiry':
          data['title'] = `Financing Products Inquiry | ${title}`;
          data['meta'] = `Contact the ${title} team about financing products that can be made available to your clients.`;
          break;
        case '/wc-loan-inquiry':
          data['title'] = `Financing Products Inquiry | ${title}`;
          data['meta'] = `Contact the ${title} team about financing products that can be made available to your clients.`;
          break;
        case '/profile-directory':
           data['title'] = `Firm Profile | ${title}`;
           data['meta'] = 'View all profile information and data here.';
          break;
        case '/account-setting':
           data['title'] = `Account Settings | ${title}`;
           data['meta'] = 'View your account settings here and customize your profile to what you want to see.';
          break;
        case '/change-password':
           data['title'] = `Change Password | ${title}`;
           data['meta'] = `Reset your password on the ${title}. Make sure your account is secure by changing your password periodically.`;
          break;
        case '/forgot-password':
           data['title'] = `Forgot Password | ${title}`;
           data['meta'] = 'Forgot your password? To reset your password, please enter your username and you will receive password reset instructions to the email address for this account.';
          break;
        case '/bank-statement':
           data['title'] = "Bank Statement| CPA Business Loans";
           data['meta'] = 'Upload and View all your uploaded bank statements for your loan application here.';
          break;
        case '/plans':
           data['title'] = "Plans | CPA Business Loans";
           data['meta'] = 'View and select the plan for your CPA Business firm.';
          break;
        case '/portal-demo-request':
           data['title'] = "Portal Demo Request | CPA Business Loans";
           data['meta'] = 'Portal Demo Request.';
          break;
    }
    return data;
  }

    /** To set pproduct config in state */
    setProductConfig(){
      let product_config = [
        {
          "slugs": [],
          "products": [
            {
              "name": "sbaloans",
              "customer": {
                "applicationSteps": {
                  "loanType": {
                    "redirects": {
                      "previous": "",
                      "next": "about-business"
                    }
                  },
                  "aboutBusiness": {
                    "redirects": {
                      "previous": "loan-type",
                      "next": "borrower-information"
                    }
                  },
                  "borrowerInfo": {
                    "redirects": {
                      "previous": "about-business",
                      "next": "owner-details"
                    }
                  },
                  "ownerDetails": {
                    "redirects": {
                      "previous": "borrower-information",
                      "next": "another-owner-detail"
                    }
                  },
                  "affiliateInfo": {
                    "redirects": {
                      "previous": "another-owner-detail",
                      "next": "another-owner-detail"
                    }
                  },
                  "ownerList": {
                    "redirects": {
                      "previous": "owner-details",
                      "next": "collateral-details"
                    }
                  },
                  "collateralDetails": {
                    "force_skip": true,
                    "redirects": {
                      "previous": "another-owner-detail",
                      "next": "sbaPrimary"
                    }
                  },
                  "sbaPrimary": {
                    "redirects": {
                      "previous": "collateral-details",
                      "next": "sba-documents"
                    }
                  },
                  "sba-documents": {
                    "force_skip": true,
                    "redirects": {
                      "previous": "sba-primary",
                      "next": "funding-information"
                    }
                  },
                  "funding-information": {
                    "redirects": {
                      "previous": "sba-documents",
                      "next": "pfs-form"
                    }
                  },
                  "pfs-form": {
                    "redirects": {
                      "previous": "funding-information",
                      "next": "application-complete"
                    }
                  }
                }
              },
              "banker": {
                "featureVariables": {
                  "creditApproval": {
                    "forms": [
                      {
                        "name": "cam_collateral",
                        "required": false
                      },
                      {
                        "name": "pfs",
                        "required": false
                      },
                      {
                        "name": "cashflow-review",
                        "required": false
                      }
                    ]
                  },
                  "certificate_letters": {
                    "prequalification_letter": {
                      "enabled": true,
                      "required": true,
                      "conditions": []
                    }
                  }
                }
              }
            }
          ],
          "_id": "5c1b3487db8be552c81ab07f",
          "settings": {
            "saveAndExit": {
              "steps":{
                "globalAccess": true,
                'loan-type': false,
                'create-profile': true,
                'about-business': true,
                'borrower-information': true,
                'owner-details': true,
                'another-owner-detail': true,
                "all_owners_detail": true,
                'financial-information': true,
                'affiliate-information': true,
                'term-sheet': true,
                'collateral-details': true,
                'sba-primary': true,
                'sba-form': true,
                'sba-form-1': true,
                'sba-form-2': true,
                'sba-form-3': true,
                'sba-form-4': true,
                'sba-form-5': true,
                'sba-form-6': true,
                'sba-form-7': true,
                'sba-form-8': true,
                "documents": true,
                'funding-information': true,
                'ifs-data': true,
                'pfs-form': true,                
              }
            },
            "knockoutConfig": {
              "application_disqualified":{
                "sub_status_id":""
              },
              "application_customer_declined": {
                "sub_status_id":"",
                "status_id":""
              }
            }
          }
        }
      ]
  
      this.store.dispatch(SetSbaProductConfig({configurations: product_config}))
  
    }

  /**
   * function to add class on mouse event to body
   */
  addMouseListener() {
    this.document.body.addEventListener('mousedown', (event: any) => {
      this.document.body.classList.add('using-mouse');
    })

    this.document.body.addEventListener('keydown', (event: any) => {
      if (event.keyCode === 9)
        this.document.body.classList.remove('using-mouse');
    });
  }

  ngAfterViewChecked() {
    this.jQuery.toggleTooltip('[data-toggle="tooltip"]');
  }



  ngOnInit(): void {
    this.client_name = this.environment?.client_name;
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.storeSubscription = this.store.
      pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if(rootState) {
          // if(!rootState?.configurations){
            this.setProductConfig()
          // }
          if (rootState.nextTask) {
            this.canAccessService.setUserData(rootState.backendUserData.user);
          }
        }
      });

      this.openUVModalSubs = this.notificationService.openUVModal$
      .pipe(
        debounceTime(300), // Avoid duplicate triggers
        tap(({ user_id, app_id, b2cRef }) => {
          // Route to dashboard 
          this.router.navigate(['/dashboard'], {
            queryParams: { user_id, app_id, b2cRef }
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.openUVModalSubs) {
      this.openUVModalSubs.unsubscribe();
    }
  }

}
