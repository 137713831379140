import { Action } from '@ngrx/store';
import { TaskInfoInterface } from '@rubicon/interface/task-info';

export enum PDF_ParseAppActionTypes {
  LoadPdfParseFormData = '[PDF_ParseApp] Load Form And App Data',
  LoadPdfParseFormLoaded = '[PDF_ParseApp] Load Form And App Data Loaded',
  LoadPdfParseFormLoadError = '[PDF_ParseApp] Load Form And App Data Load Error',
  SetPdfParseFormLoaded = '[PDF_ParseApp] Set PDF Parse App Data',
  StateInit = "@ngrx/store/init"
}

export class LoadPdfParseFormData implements Action {
  readonly type = PDF_ParseAppActionTypes.LoadPdfParseFormData;
  constructor(public payload: any) { }
}


export class LoadPdfParseFormLoadError implements Action {
  readonly type = PDF_ParseAppActionTypes.LoadPdfParseFormLoadError;
  constructor(public payload: any) {}
}

export class SetPdfParseFormLoaded implements Action {
  readonly type = PDF_ParseAppActionTypes.SetPdfParseFormLoaded;
  constructor(public payload: TaskInfoInterface) {}
}

export class LoadPdfParseFormLoaded implements Action {
  readonly type = PDF_ParseAppActionTypes.LoadPdfParseFormLoaded;
  constructor(public payload: TaskInfoInterface) {}
}

export type PdfParseAppAction = 
|LoadPdfParseFormData 
|LoadPdfParseFormLoadError
|LoadPdfParseFormLoaded
|SetPdfParseFormLoaded


export const fromPdfParseAppActions = {
  LoadPdfParseFormData,
  SetPdfParseFormLoaded
};

