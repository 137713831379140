<div [formGroup]="group" [ngClass]="(field?.css?.parent)? ( is_hidden ? field?.css?.parent+' d-none' : field?.css?.parent) : (is_hidden ? 'col d-none': 'col' ) ">
    <div [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 mb-0'">
        <span [innerHTML]='slug + ".label." + field.name | translate'></span>
        <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
        --><a  class='que_info-text' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
        --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
        --></ng-container><!--
        --></div>
    <div [ngClass]="(field?.css?.wrapper)? field?.css?.wrapper :'option-flex'">
        <div *ngFor="let val of field.options"
            [ngClass]="field?.css?.inputParent ? field?.css?.inputParent: 'md-radio md-radio-inline'">
            <ng-container *ngIf="field?.css?.boxType; else normal">
                <label [ngClass]="(field?.css?.label)? field?.css?.label :'labeltext'" [for]="val.type +'_' + indexVal">
                    <input [ngClass]="(field?.css?.input)? field?.css?.input :'check11'" type="radio" [value]="val.id"
                        [formControlName]="field.name" [id]="val.type +'_' + indexVal"
                        [ngClass]="val.option_custom_class ? val.option_custom_class : '' "
                        ><label
                        id="is_applicnt_suspended_error"
                        class="error"></label><span></span><em>{{slug + ".label." + val.type | translate}}</em>
                </label>
            </ng-container>
            <ng-template #normal>
                <input class="form-check-input" type="radio" [value]="val.id" [formControlName]="field.name"
                    [ngClass]="val.option_custom_class ? val.option_custom_class : '' "
                    [id]="val.type +'_' + indexVal">
                <label class="form-check-label rlt" [for]="val.type +'_' + indexVal">
                    {{slug + ".label." + val.type | translate}}
                </label>
        </ng-template>
        </div>
    </div>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg" [ngClass]="(field?.css?.error)? field?.css?.error :''">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
    </div>
</div>