import {
  // BusinessStructureAction,
  PDF_ParseAppActionTypes,
  PdfParseAppAction
} from './pdf-parse-app.actions';

export const PDP_PARSE_APP_FEATURE_KEY = 'pdf_parse_app';


export interface PDFParseAppState {
  loaded: boolean; // has the LendingNeeds list been loaded
  error?: any; // last none error (if any)
  loading: boolean;
  taskInfo: any;
  response?: any;
  errors?: any;
  formData?: any;
  parsedData?:any
}

export interface PDFParseAppPartialState {
  readonly [PDP_PARSE_APP_FEATURE_KEY]: PDFParseAppState;
}

export const initialState: PDFParseAppState = {
  loaded: false,
  loading: false,
  taskInfo: null,
  response: null,
  parsedData: null
  
};

export function reducer(
  state: PDFParseAppState ,
  action: PdfParseAppAction
): PDFParseAppState {
  switch (action.type) {
    case PDF_ParseAppActionTypes.LoadPdfParseFormData:
      {
        state = {
          ...state,
          response: null,
          loading: true,
        };
      }
      break;
    case PDF_ParseAppActionTypes.LoadPdfParseFormLoaded:
      {
        state = {
          ...state,
          taskInfo: action.payload,
          response: null,
          loaded: true
        };
      }
      break;
    case PDF_ParseAppActionTypes.SetPdfParseFormLoaded:
      {
        state = {
          ...state,
          parsedData: action.payload
        };
      }
      break;
  }
  return state;
}
