import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { CommonService } from '@rubicon/utils';

@Component({
  selector: 'rubicon-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  autoData: any;
  indexVal: Number;

  constructor(
    @Inject("environment") public environment) { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
  }
}