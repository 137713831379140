import { SBA_CONSTANTS } from '../constant/constant';

export const APP_ROUTES = {
    '': '',
    'dashboard': 'dashboard',
    'loan-info': 'loan-info',
    'about-you': 'about-you',
    // 'connect-sources': 'connect-sources',
    'bank-statement':'bank-statement',
    'question-answer': 'question-answer',
    'payroll-schedule': 'payroll-schedule',
    'eligible-forgiveness': 'eligible-forgiveness',
    'documents': 'documents',
    'thankyou' : 'thankyou',
    'thankyou-logout': 'thankyou',
    'page-not-found': 'page-not-found',
    'application-view-v2':'application-view-v2',

    'eligibility': 'ppp2/eligibility',
    'eligibility-question':'ppp2/eligibility-question',
    'payroll-details':'ppp2/payroll-details',
    'employee-details':'ppp2/employee-details',
    'eligibility-values':'ppp2/eligibility-values',
    'offer-details':'ppp2/offer-details',
    'borrower-business':'ppp2/borrower-business',
    'payroll-info':'ppp2/payroll-info',
    'forgiveness-eligibility':'ppp2/forgiveness-eligibility',
    'upload-documents':'ppp2/upload-documents',
    'government-declaration':'ppp2/government-declaration',
    'government-declaration-step2': 'ppp2/government-declaration-step2',
    'loan-purpose':'ppp2/application-overview',
    'business-details-ppp2-1':'ppp2/business-info',
    'business-details-ppp2-2':'ppp2/business-info-2',
    'applicant-details-ppp2':"ppp2/owner-info",
    'document-listing-ppp2-1':"ppp2/documents",
    'document-listing-ppp2-2':"ppp2/documents/upload",
    "ppp2-esign":"ppp2/esign",
    "before-you-start":"ppp2/before-you-start",
    "ppp-loans": "ppp2/ppp-loans",
    "previous-loan-info": "ppp2/previous-loan-info",
    'revenue-reduction':'ppp2/revenue-reduction',
    'application-view':'ppp2/application-view',
    
    //term loan routes
    'term-loan-business-structure':'term-loan/business-structure',
    'term-loan-owners-details':'term-loan/owner-details',
    'term-loan-business-info':'term-loan/business-info',
    'term-loan-details': 'term-loan/loan-details',
    'term-loan-document-management': 'term-loan/document-management/upload',
    'term-loan-app-view':'term-loan/application-view',
    'pre_qualification':'term-loan/pre-qualification',
    'wc-pre-qualification':'revenue-based-financing/pre-qualification',
    'wc-business-structure':'revenue-based-financing/business-structure',
    'wc-owners-details':'revenue-based-financing/owner-details',
    'wc-business-info':'revenue-based-financing/business-info',
    'wc-loan-details': 'revenue-based-financing/loan-details',
    'wc-document-management': 'revenue-based-financing/document-management/upload',
    'wc-app-view':'revenue-based-financing/application-view',
    'ertc-app-view':'ertc/application-view',
    'ertc-pre-qualification':'ertc/pre-qualification',
    'ertc-business-structure':'ertc/business-structure',
    'ertc-owners-details':'ertc/owner-details',
    'ertc-business-info':'ertc/business-info',
    'ertc-loan-details': 'ertc/loan-details',
    'ertc-document-management': 'ertc/document-management/upload',

    //sba 
    // 'loan-type': 'sba/loan-request',
    // 'about-business' : 'sba/business-details/about-business'
    'sba-app-view' : `sba/application-view`,
    'sba-loan-inquiry-thankyou' : 'sba-loan-inquiry/thankyou',
    'wc-loan-inquiry-thankyou' : 'working-capital-loan-inquiry/thankyou',

    ...SBA_CONSTANTS.APP_ROUTES
    
}


export const APP_STEPS_INFO = {
    'loan-info': 'PPP Loan Application Details',
    'about-you': 'Business Details',
    'bank-statement': 'Data Sources',
    // 'connect-sources': 'Send a link to the customer for connecting Payroll Processor.',
    'question-answer': 'Start by answering the following questions',
    'payroll-schedule': 'Employee payroll schedule',
    'eligible-forgiveness':  'Forgiveness eligibility calculations',
    'documents': 'Upload Additional Required Documents',
    'application-view-v2' : 'Application View'
}

export const APP_STEP = {
    'loan-info': 1,
    'about-you': 2,
    'bank-statement' :3,
    'question-answer':4,
    'payroll-schedule': 5,
    'eligible-forgiveness':6,
    'documents': 7,
    'application-view-v2' : 9
}
export const CONSTANTS={
    "yes":"5e8723158f2f4e3ac475fae6",
    "no":"5e8723158f2f4e3ac475fae7",
    B2C_TO_CPA_STATUS_MAP:{
        "loan forgiven - sba ppp": "Loan forgiven",
        "temporary hold till March 9":"On Hold by SBA",
        "temporary hold till March 9_tooltip":"Your application indicates your business has at least 20 employees. During this time the Small Business Administration (SBA) has opted to hold applications submitted by businesses with 20 employees or more. Your application is ready for submission into the SBA once they resume applications. At that time, your application will be submitted into the SBA system.",
        "package ready for sba ppp":"Submitted to SBA",
        "package ready for sba ppp_tooltip":"Your client's application has been submitted to the Small Business Administration for processing. It will take no less than 48 hours before the SBA has issued a decision on your client's application. For larger loan amounts, the processing time may be even longer.",
         "application rejected":"Duplicate Application Found",
         "application rejected_tooltip":"This client's business already has an application that is queued in the Biz2Credit system to be submitted into the SBA. The SBA does not allow duplicate submissions for the same business for PPP applications. This application cannot be submitted due to this. Please contact your client as it appears they have already submitted their application through another source, such as their payroll provider.", 
         "declined by ppp lender":"Not Accepted by Lender",
         "declined by ppp lender_tooltip":"Unfortunately, the information provided on the application could not be accepted by the lender to issue a PPP loan in accordance with SBA regulations.",
         "declined by underwriter":"Not Accepted by Lender",
          "declined by underwriter_tooltip":"Unfortunately, the information provided on the application could not be accepted by the lender to issue a PPP loan in accordance with SBA regulations.",
          "declined by sba":"Not Accepted by SBA",
          "declined by sba_tooltip":"Unfortunately, the SBA did not accept this loan application. Click on View Application to see the information available about this decision.",
          "ready for review uw - sba-ppp":"Approved",
          "ready for review uw - sba-ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "ready for review - sba-ppp":"Approved",
          "ready for review - sba-ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "approved sba ppp":"Approved",
          "approved sba ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "approved - sba ppp":"Approved",
          "approved - sba ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "returned to sales - documents missing":"More Information Needed",
          "returned to sales - documents missing_tooltip":"The loan verification team has identified further information that is required in order to fund your client's PPP loan. Please contact the client to find out the specific requirements that they have requested.",
          "returned to sales":"More Information Needed",
          "returned to sales_tooltip":"The loan verification team has identified further information that is required in order to fund your client's PPP loan. Please contact the client to find out the specific requirements that they have requested.",
        //   "returned to sales - forgiveness documents missing":"More Information Needed",
          "returned to sales - forgiveness documents missing_tooltip":"The loan verification team has identified further information that is required in order to fund your client's PPP loan. Please contact the client to find out the specific requirements that they have requested.",
          "new documents uploaded":"Information Under Review",
          "new documents uploaded_tooltip":"Your client has provided further information to the loan verification team. They will be reviewing. This process may take anywhere from 24-72 hours.",
          "contract requested sba ppp":"Approved",
          "contract requested sba ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "loan closing sba ppp":"Approved",
          "loan closing sba ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "loan closing - sba ppp":"Approved",
          "loan closing - sba ppp_tooltip":"Your client's application is approved by the SBA! The next step is for your client to provide their bank account details in their Biz2Credit account. They will receive an email with instructions on what to do next.",
          "contract sent sba ppp":"Contract Pending",
          "contract sent sba ppp_tooltip":"Your client has received the SBA PPP Loan Note that they must sign. Please remind them to check for an e-Sign link in their email.",
          "contract sent - sba ppp":"Contract Pending",
          "contract sent - sba ppp_tooltip":"Your client has received the SBA PPP Loan Note that they must sign. Please remind them to check for an e-Sign link in their email.",       
          "sent to accounting":"Contract Signed",
          "sent to accounting_tooltip":"The contract is signed, and your client's loan is nearly funded!",
           "funding complete sba ppp":"Funded",
           "funding complete sba ppp_tooltip":"Your client's PPP loan is now funded. Check your My Plan page for information on your Agent Fees for this loan.",
           "funding complete - sba ppp":"Funded",
           "funding complete - sba ppp_tooltip":"Your client's PPP loan is now funded. Check your My Plan page for information on your Agent Fees for this loan.",
           "expired":"Program Funding Expired",
           "expired_tooltip":"The Paycheck Protection Program is no longer accepting applications due to lack of funding. You maybe able to find a lender that is till accepting PPP applications by using Lender Match on <a href='https://www.sba.gov/funding-programs/loans/lender-match' class='newtabLink' target='_blank'>SBA.gov</a> ",
           "withdrawn":"Withdrawn",
           "withdrawn_tooltip":"Your client has contacted the loan verification team and has requested that the loan application be withdrawn. Please contact your client for more information.",
            //    "case pending loan forgiveness": "Application Submitted",
            //    "forgiveness application in progress":"Application Submitted",
            //    "documents requested – forgiveness.":"Application Submitted",
            //    "applied for forgiveness": "Applied for Forgiveness",
            //    "ready to send to sba forgiveness": "Applied for Forgiveness",
            //    "sent to sba forgiveness": "Applied for Forgiveness",
            //"returned to sales - forgiveness documents missing": "Returned to Sales - Forgiveness documents missing",
            //    "sent to lender – Forgiveness": "Applied for Forgiveness",
            //    "loan forgiven": "Loan Forgiven",
            //    "partial forgiveness": "Partial Forgiveness",
            //    "forgiveness declined":"Forgiveness Declined",
            //    "case in payback": "Case in Payback", 
            //    "declined due to fraud":"Declined Due to Fraud",

            "case pending loan forgiveness": "Pending loan forgiveness",
            "forgiveness application in progress": "Pending loan forgiveness",
            "documents requested – forgiveness.":"Pending loan forgiveness",
            "applied for forgiveness":"Applied for Forgiveness",
            "ready to send to sba forgiveness":"Applied for Forgiveness",
            "sent to sba forgiveness":"Applied for Forgiveness",
            "returned to sales - forgiveness documents missing":"Applied for Forgiveness",
            "sent to lender - forgiveness":"Applied for Forgiveness",
            "loan forgiven":"Forgiveness Processed",
            "partial forgiveness":"Forgiveness Processed",
            "forgiveness declined":"Forgiveness Processed",
            "case in payback":"Case in Payback",
            "declined due to fraud":"Declined Due to Fraud",
            "expired – sba hold":"Expired – SBA Hold",
            "expired – sba hold_tooltip":"The SBA has announced that applications that received Hold Code error messages may no longer be processed by most PPP lenders. Borrowers whose applications encountered Hold Codes may still be able to apply for a PPP loan with a Community Financial Institution (CFI) while funding remains available and before the final program deadline of May 31, 2021. Use Lender Match on SBA.gov to locate an eligible lender.",
            "forgiveness_on_hold_tooltip": "This application has been placed on hold based on a processing requirement. It is now with the Biz2Credit Under Review team. The review process may take up to 60 days to complete. Until the process is completed, it cannot be submitted. Contact cpasupport@biz2credit.com if you need assistance with this application.",
            "submitted to itria": "Submitted to Biz2Credit",
            "submitted to itria_tooltip":"The application has been submitted to the underwriting department at Biz2Credit. You and your client will be notified once a decision is in progress.",
            "offer pending": "Submitted: Review in Progress",
            "offer pending_tooltip": "Biz2Credit's underwriting department is actively reviewing the documentation and data provided in this application. This may take some time. The next step is for underwriting to issue a decision.",
            "approved by underwriter": "Approved",
            "approved by underwriter_tooltip": "The underwriting department is completing final checks of the application in order to issue a funding decision and offer, if applicable. You and your client will be notified when a decision is reached.",
            "offer accepted": "Offer Accepted",
            "offer accepted_tooltip": "A funding offer has been extended to your client. You can view the details of this offer by viewing Application Details.",
            "offer accepted / stips pending": "Offer Available - More Documents Required",
            "offer accepted / stips pending_tooltip": "A funding offer has been prepared for your client, but there are still documents required before this offer can be finalized. Review Underwriting Notes to see what is outstanding. Your client has also been notified.",
            "ready for review": "Document Review in Progress",
            "ready for review_tooltip": "Biz2Credit's underwriting department is reviewing final documentation and stipulations for this application. You and your client will be notified when an update is available.",
            "ready for funding": "Document Review in Progress",
            "ready for funding_tooltip": "Biz2Credit's underwriting department is reviewing final documentation and stipulations for this application. You and your client will be notified when an update is available.",
            "funding call requested": "Offer Made to Client",
            "funding call requested_tooltip":"A funding offer has been extended to your client. You can view the details of this offer by viewing Application Details.",
            "funding call completed": "Offer Made to Client",
            "funding call completed_tooltip":"A funding offer has been extended to your client. You can view the details of this offer by viewing Application Details.",
            "incomplete documents": "Missing Documents [See Underwriting Notes]",
            "incomplete documents_tooltip": "The underwriting department has identified missing or incomplete documentation that will need to be corrected in order for the application to progress. Please review the Underwriting Notes section to see the open requirements.",
            "uw revision requested": "Submitted: Decision in Progress",
            "uw revision requested_tooltip": "The underwriting department is reviewing the application in order to issue a funding decision and offer, if applicable. You and your client will be notified when a decision is reached.",
            "potential startup candidate": "Not Qualified",
            "potential startup candidate_tooltip": "Unfortunately this application could not be accepted as the client did not meet the eligibility criteria to qualify for this type of financing at this time.",
            "does not qualify": "Not Qualified",
            "does not qualify_tooltip": "Unfortunately this application could not be accepted as the client did not qualify at this time. You may review details in the Underwriting Notes section.",
            "borrower not interested": "Client Declined Offer",
            "borrower not interested_tooltip": "Your client has declined to proceed with the financing application at this time.",
            "declined by borrower": "Offer Declined",
            "declined by borrower_tooltip": "Your client has declined to proceed with the financing application at this time.",
            "qualified not interested": "Client Declined Offer",
            "qualified not interested_tooltip": "Your client has declined to proceed with the financing application at this time.",
            "declined by system": "Declined",
            "declined by system_tooltip": "Unfortunately this application could not be accepted as the client did not qualify at this time. You may review details in the Underwriting Notes section.",
            "declined by lender": "Declined",
            "declined by lender_tooltip": "Unfortunately this application could not be accepted as the client did not qualify at this time. You may review details in the Underwriting Notes section.",
            "missing funding stipulations": "Missing Documents [See Underwriting Notes]",
            "missing funding stipulations_tooltip": "The underwriting department has identified missing or incomplete documentation that will need to be corrected in order for the application to progress. Please review the Underwriting Notes section to see the open requirements.",
            "offer decision override": "Document Review in Progress",
            "offer decision override_tooltip": "The underwriting department is reviewing the application at this time. You and your client will be notified in case there are any outstanding requirements, or when a funding decision is reached.",
            "funded": "Funded",
            "funded_tooltip": "Your client has accepted their funding offer and the disbursement has been made. If you or your client suspect there is any issue in transfer, please allow 48-72 hours for your client's receiving bank to process the transaction before contacting customer service for assistance.",
            "working_capital_tooltip": "This application was originally submitted for a Term Loan. During underwriting, the product was reassigned based on client qualifications or by request.",
            "ertc_capital_tooltip": "This application was originally submitted for a Term Loan or Revenue-Based Financing. During underwriting, the product was reassigned based on client qualifications or by request.",
            "declined by itria": "Declined",
            "declined by itria_tooltip": "Unfortunately this application could not be accepted as the client did not qualify at this time. You may review details in the Underwriting Notes section.",
            "incomplete docs by verifier": "Missing Documents [See Underwriting Notes]",
            "incomplete docs by verifier_tooltip": "The underwriting department has identified missing or incomplete documentation that will need to be corrected in order for the application to progress. Please review the Underwriting Notes section to see the open requirements.",
            "declined by verifier": "Declined",
            "declined by verifier_tooltip": "Unfortunately this application could not be accepted as the client did not qualify at this time. You may review details in the Underwriting Notes section.",
            "submitted to specialist": "Submitted to Specialist",
            "application started":"Submitted to Lender",
            "application complete": "Submitted to Lender",
            "application under review": "Submitted - Being Reviewed",
            "submitted to underwriter":"Submitted: Review in Progress",
            "document pending":"Missing Documents [See Underwriting Notes]",
            "approved":"Approved",
            "offers pending":"Submitted - Decision in Progress",
            "criteria don't match": "Criteria Don't Match",
            "qualified, docs requested":  "Qualified, Docs Requested"
        },
    B2C_TO_CPA_STATUS_MAP_SBA: {
        "application started":"Submitted to Lender",
        "application complete": "Submitted to Lender",
        "declined by lender": "Declined",
        "withdrawn":"Withdrawn",
        "submitted to underwriter":"Submitted: Review in Progress",
        "approved":"Approved",
        "document pending":"Missing Documents [See Underwriting Notes]",
        "application under review": "Submitted - Being Reviewed",
        "funded": "Funded",
        "criteria don't match": "Criteria Don't Match",
        "offers pending":"Submitted - Decision in Progress",
        "approved by underwriter" : "Approved by Underwriter"
    },
    BUSINESS_TYPES : ['5e8d97116b81935bc8af0300' ,'5e8d97116b81935bc8af0301'],
    BUSINESS_STRUCTURES : ['Individual' ,'Sole Proprietorship'],
    DOC8821: '8821_form',
    APP_TYPE: {
        forgiveness : "5cf61ae8a567c9873cbab4d5",
        ppp2: "5f40fb82b365b23a003e3708",
        term_loan: "616415139c0b772898330eec",
        working_capital: '62260b1e7cdf3e5be1eb6be4',
        ertc: '62b17842a2d8a20494cf846c',
        sba: '63a0485a27f0b23bf982e256'
    },
    DOCUMENT_LIST: {
        COMPANY_DOCUMENTS: [
            'form_8821',
            'IRS_941',
            'bank_statements',
            'payroll_report_or_text_forms_irs_941',
            'payment_receipts_cancelled_checks',
            'average_fte_employed_feb_2019_june',
            'average_fte_employed_jan_2020_feb',
            'business_mortgage_interest',
            'business_rent_or_lease_payments',
            'business_utility_payments',
            'payroll_documents',
            'fte_documents',
            'non_payroll_documents',
            'additional_documents',
            'form_3508_documents',
            'form_3508',
            'operations_expenditures_receipts', //added for new documents list SBA-2324
            'property_damage_receipts', //added for new documents list SBA-2324
            'supplier_cost_receipts', //added for new documents list SBA-2324
            'protection_expenditure', //added for new documents list SBA-2324
            '2019_irs_form_1099_schedule_c', //added for submit to marketplace
            "proof_mortgage_rent_utility_feb_15", //added for submit to marketplace
            "reduction_proof"
        ],
        INDIVIDUAL_DOCUMENTS: [
            'form_8821',
            'IRS_941',
            'bank_statements',
            'payroll_report_or_text_forms_irs_941',
            'payment_receipts_cancelled_checks',
            'average_fte_employed_feb_2019_june',
            'average_fte_employed_jan_2020_feb',
            'business_mortgage_interest',
            'business_rent_or_lease_payments',
            'business_utility_payments',
            'payroll_documents',
            'fte_documents',
            'non_payroll_documents',
            'additional_documents',
            'form_3508_documents',
            'form_3508',
            'operations_expenditures_receipts', //added for new documents list SBA-2324
            'property_damage_receipts', //added for new documents list SBA-2324
            'supplier_cost_receipts', //added for new documents list SBA-2324
            'protection_expenditure', //added for new documents list SBA-2324
            '2019_irs_form_1099_schedule_c', //added for submit to marketplace
            "proof_mortgage_rent_utility_feb_15", //added for submit to marketplace,
            "reduction_proof"
        ],

    },
    SUBMITTED_STATE:"5c20bf7e27105c78ad7f9280",
    NEW_STATE:'5c20bf7e27105c78ad7f9283',
    APPROVED_STATE: "5d1618f27823310fb44b9d4e",
    ADDENDUM_CSS:"*{margin:0;padding:0;-webkit-box-sizing:border-box;-webkit-print-color-adjust:exact;-moz-box-sizing:border-box;box-sizing:border-box}body{color:#000;font-family:\"Times New Roman\",Times,serif;font-style:normal;font-size:8pt;margin-bottom:0;margin-top:0}.clear{margin:0;padding:0;height:0;clear:both}div,p,li,ul,span,td,th,a{margin:0;padding:0}p{padding-bottom:10px;line-height:17px;color:#000}.wraperPage{width:840px;margin:0 auto}.newPage{width:100%;display:block}.wrap{width:100%;padding-bottom:10px}.wraperPage .header{padding-bottom:15px}.heading{padding:4px 6px;font-size:9pt;margin-bottom:12px;color:#fff;background:#034048;font-style:normal}.clearfix{margin:0;padding:0;height:0;clear:both}.div50{float:left;width:50%}.wrap{width:100%;display:inline-block;margin-bottom:2px}ul li{line-height:15px}th{color:#000}.addendum-header td{font-weight:bold;font-size:10pt;text-align:center;color:#000;padding-bottom:10px}.main-heading{font-size:12pt}p.addendum-p{padding:0 0 5px 0;text-align:left;}.tableContainer{padding-bottom:10px}.mainTable{border:1px solid #000;border-bottom:0}.mainTable th{border-bottom:1px solid #000;padding:3px;text-align:center;border-bottom:1px solid #000;border-right:1px solid #000}.mainTable td{border-bottom:1px solid #000;border-right:1px solid #000;padding:3px;text-align:left;height:40px}.explanation-p{padding:0 0 10px} .noWrap{white-space: nowrap;}.mt-20{margin-top:20px;}p.pb-20{padding-bottom:20px;}.pb-30{padding-bottom:30px;}",
    OWNER_STRUCT_CSS:"*{margin:0px;padding:0px; -webkit-box-sizing:border-box; -webkit-print-color-adjust: exact; -moz-box-sizing:border-box;box-sizing:border-box;}body {color: #000;font-family: \"Times New Roman\", Times, serif;font-style: normal; font-size:9pt; margin-bottom: 0; margin-top: 0;}div, p, li, ul, span, td, th, a { margin: 0; padding: 0;}p { padding-bottom: 10px;}.wraperPage { width:780px; margin: 0 auto;}.newPage { width: 100%; display: block;}.wrap { width: 100%; padding-bottom: 10px;}.wrap { width: 100%; display: inline-block; margin-bottom: 2px;} .heading{font-weight: bold; font-size: 11pt; text-align: center;}.right{text-align:right;}.mainTable{border: 1px solid #000;}.head-row{background: #d9d9d9;border-right: 1px solid #000;font-weight: bold; padding:3px 6px; }.center{text-align:center}.cell{border-right: 1px solid #000;border-top: 1px solid #000;}.padded-cell{padding:3px 6px;}.br-0{border-right:none;}",
    ...SBA_CONSTANTS
}


export const MESSAGES = {
    "loan-info": "The SBA will use the borrower's Paycheck Protection Program loan information to match up the borrower's forgiveness request with the original loan that was granted. Please input the details here as they appeared on the borrower's final application, or on the loan closing documents.",
    "about-you": "Details you enter here should reflect the information of the borrowing business (these should match what was on the original PPP loan application) and the owner or primary designated representative of the organization.",
    "connect-sources": "",
    "question-answer": "",
    "payroll-schedule": "We need accurate and complete information to process forgiveness of your loan",
    "eligible-forgiveness": "",
    "documents": "",
    "thankyou" : "",
    "thankyou-logout": "",
    "page-not-found": "",
    "application-view-v2": ""
}

export const PPP2_STEPS_INFO = {
    "eligibility": "Loan Eligibility",
    "eligibility-question":"Loan Eligibility",
    "payroll-details":"Payroll Details",
    "employee-details": "Employee Details​",
    "eligibility-values":"Enter Eligibility Values",
    "borrower-business": "About borrower's business",
    "payroll-info": "Payroll information",
    "forgiveness-eligibility": "Forgiveness eligibility",
    // "upload-documents": "Upload documents",
    "government-declaration": "Gov't Declarations",
    'applicant-details-ppp2':"Ownership Information",
    'business-details-ppp2-1':'Business Information',
    'business-details-ppp2-2':'Business Information',
    'loan-purpose':'Application Overview',
    "government-declaration-step2": "Gov't Declarations (50% Complete)",
    // "esign": "Review and Sign the Application​",
    'document-listing-ppp2-1':"Upload documents",
    "ppp2-esign":"Review and Sign the Application​",
    'previous-loan-info': 'Previous Loan Information',
    "revenue-reduction": "Revenue Reduction",
    "application-view": "Application View"
}

export const PPP2_MESSAGES = {
    'applicant-details-ppp2':"",
    "eligibility": "",
    "eligibility-question":"We need a little more information so we can use the right eligibility checks for your client's business. Answering these questions as accurately as possible assists in using the correct application to streamline the application process.",
    "payroll-details":"The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria and​ who need the funding to keep their employees on payroll during the COVID-19 pandemic. The details you​ provide here are used to verify your client's eligibility for the program according to government rules.​",
    // "employee-details": "The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria. In general​ this requires companies to have fewer than 500 employees or meet the <a href='https://www.sba.gov/sites/default/files/2019-08/SBA%20Table%20of%20Size%20Standards_Effective%20Aug%2019%2C%202019.pdf' class='uline' target='_blank'>SBA's minimum size standards</a>​ for their particular industry. In addition, there are some payroll costs that are excluded. The next few steps walk through those exclusions.​",
    "eligibility-values":"The Paycheck Protection Program is open to businesses that meet the SBA’s small business criteria and​ who need the funding to keep their employees on payroll during the COVID-19 pandemic. The details you​ provide here are used to verify your client's eligibility for the program according to government rules.",
    "borrower-business": "About borrower's business",
    "payroll-info": "Payroll information",
    "forgiveness-eligibility": "Forgiveness eligibility",

     // "upload-documents": "Upload documents",
     "loan-purpose": "Review the information before sending to the client/borrower for signature.",
    "government-declaration": "Government Required Declarations",
    "government-declaration-step2": "Government Required Declarations",
    // "esign":"",
    'business-details-ppp2-2':'Business Information',
    'document-listing-ppp2-1':`<b>PPP applications can no longer be submitted via the CPA Business Funding Portal.</b> <br>Please do not upload documents below. You can download the completed SBA Form 2483/2483-SD under "SBA 2483 eSign" if your client has reviewed and signed the form.<br> You can take the signed Form 2483/2483-SD to a Community Financial Institution (CFI) that is continuing to take applications while program funding remains allocated to these specialty lenders. You can locate an available lender using Lender Match on SBA.gov.`,
    "ppp2-esign":"Review and Sign the Application​",
    "previous-loan-info": "Second Draw Loans under the Paycheck Protection Program (PPP) are governed by the rules enacted under the law. Borrowers may not receive more than $10 million between first and second draw loans combined, including across all affiliated businesses.",
    "revenue-reduction": "Businesses requesting a Second Draw Loan under the PPP are required to certify that their business experienced at least a 25% reduction in revenue (gross receipts) due to the Coronavirus pandemic. Please indicate which quarter(s) should be used to qualify your business."
}

export const TERMLOAN_STEPS_INFO = {
    "term-loan-details": "Provide a few more details",
    "business-details":"Business Details",
    "owner-details":"Owner Details",
    "documents":"Upload Documents",
    "term-loan-business-structure": "Business Financial Information",
    "term-loan-business-info": "Tell us more about your client's business.",
    "term-loan-finance-info": "Tell us more about your client's business.",
    "term-loan-owners-details": "Great, please provide your client's ownership details"
}

export const TERMLOAN_MESSAGES = {
    "business-details":"Tell us more about your business",
    "owner-details":"Business Financial Information",
    "documents":"Upload supporting documents",
    "term-loan-business-structure": "We would require your client's business financial information.",
    "document-management": "Upload Documents",
    "term-loan-business-info": "We need a few more details to process your application.",
    "term-loan-owners-details": "All Beneficial Owners of the business with 20%+ stake in the company must be named on the application in order for the application to be approved. These owners will as a result receive a credit consent request and a credit check will be performed."
}

export const SOCK_EVENTS = {
    "APPLICATION_UPDATE":'application_update'
}

export const ERR_MSG_BY_CODE = {
    "4": "PPP Application is on Declined by borrower",
    "5": "PPP Application is on Declined by lender",
    "39": "PPP Application is on Declined by itria",
    "58": "PPP Application is on Declined by COVID -19",
    "74": "PPP Application is on Declined by Underwriter",
    "75": "PPP Application is on Declined by PPP Lender",
    "76": "PPP Application is on Declined by SBA",
    "82": "PPP Application is on Withdrawn",
    "100": "PPP Application is on Forgiveness Declined",
    "103": "PPP Application is on Expired",
    "104": "PPP Application is on Declined by System",
    "107": "PPP Application is on Forgiveness On Hold",
    "117": "PPP Application is on Declined due to Fraud",
    "118": "PPP Application is on Expired - SBA Hold"
}

export const PLAN_FEATURE_CONFIG = {
    "6566ffe20fbc390097f1c772": {
        "id":'6566ffe20fbc390097f1c772',
        "type": "basic",
        "upgrade": true,
        "upgrade_to": ["659f7fd5ce7e57cd1b19290d"],
        "downgrade": false,
        "cancel": false,
    },
    "659f7fd5ce7e57cd1b19290d": {
        "id":'659f7fd5ce7e57cd1b19290d',
        "type": "new_premium",
        "upgrade": false,
        "downgrade": true,
        "downgrade_to": ["6566ffe20fbc390097f1c772"],
        "cancel": false,
    },
    "5e43ab3deeed3a3c3092c40c": {
        "id":'5e43ab3deeed3a3c3092c40c',
        "type": "premium",
        "upgrade": false,
        "upgrade_to": ["5f3fa4d5b0ab674488f010e7"],
        "downgrade": false,
        "cancel": true,
    },
    "5f3fa4d5b0ab674488f010e7": {
        "id":'5f3fa4d5b0ab674488f010e7',
        "type": "enterprise",
        "upgrade": false,
        "downgrade": false,
        "downgrade_to": ["5e43ab3deeed3a3c3092c40c"],
        "cancel": true,
    }
}

export const STATIC_URL = {
    "representative_ask":"https://cpacom.zoom.us/webinar/register/WN_6LSme17XQ_ax0xOW8wu6fw#/registration",
    "representative_onboarded": "https://cpacom.zoom.us/webinar/register/WN_1XXzg_YqRDGdoWlmcKbz4Q#/registration",
    "cpa_com": "https://www.cpa.com/",
    "privacy_termLoan_url": "https://www.biz2x.com/privacy-policy/",
    "terms_conditionurl" : "https://www.biz2credit.com/terms-of-use",
    "business_finance_servicesterms"   : "https://www.biz2credit.com/bfs-tc",
    "resourceHub_webcast_url": "https://register.gotowebinar.com/rt/6230800872579603215",
    "ertc_loan_enquiry_url": "https://cpa.biz2credit.com/ertc-loan-inquiry",
    "resourceHub_access_library_url" : "https://vimeo.com/showcase/7655915?page=1",
    "resourceHub_webinar_url" : "https://www.gotostage.com/channel/cpa-business-funding-portal",
    "termLoan_assets": "assets/files/CPA_BFP_ERTC_Checklist.pdf",
    "download_clientGuideBook":"assets/files/CPA-PPP-client-walkthrough.pdf",
    "download_termLoanGuideBook": "assets/files/CPA-TL-client-walkthrough.pdf",
    "white_paperPdf": "assets/files/Financing_Advisory_Whitepaper.pdf",
    "cpa_mail": "cpasupport@biz2credit.com",
    "footer_terms": "/terms-of-use/",
    "footer_privacy": "https://www.biz2credit.com/privacy-policy/",
    "biz2credit": "https://www.biz2credit.com/",
  }




// add routes which is not required to be visible.
export const INVALID_ROUTES = []

export const PRODUCT_TYPES = {
    TERM_LOAN : '616415139c0b772898330eec',
    WORKING_CAPITAL : '62260b1e7cdf3e5be1eb6be4',
    ERTC: '62b17842a2d8a20494cf846c',
    SBA_7A: '63a0485a27f0b23bf982e256',
    PPP2: '5f40fb82b365b23a003e3708',
    FORGIVENESS: '5cf61ae8a567c9873cbab4d5',
}