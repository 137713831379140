import { Injectable, Inject, isDevMode } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { concatMap, map, catchError, take } from 'rxjs/operators';
// import { environment } from '@env/environment';
import { SecurityService } from '../security/security.service';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  skipSlug = ['manual-upload-bank-statements', 'download-documents', 'get_8821_document', 'utility_parser_async', 'document-listing', 'delete-documents', 'verify_hash', 'expire_hash', 'application-by-userid', 'paychex', 'plaid', 'update_payroll_processor', 'upload-application-data', 'sba_calculator_cpa','loan-info-forgiveness','utility_parser_async_dataSource', 'cpabusiness-loan-info-forgiveness','update_email_link_status','decrypt-from-cpa','update_user_terms','fte_calculator_cpa','get-zip-from-link', 'generate-pdf','get-urjanet','download-urjanet-doc','borrower-form','upload_owner_documents,send-owner-esign', 'clear-report-upload','upload_docs_bckdUser', 'manual-upload-bank-statements-sba', 'manual-upload-bank-statements-owner','upload_firm_image', 'upload-pdf-for-parsing'];

  constructor(private store: Store<any>, private security: SecurityService, @Inject('environment') private environment) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select('app')
      .pipe(
        take(1),
        concatMap((state) => {
          const slug = request.params.get('slug');
          if (this.environment.secure && !request.url.includes('en.json') && !this.skipSlug.includes(slug)) {
            let params = this.security.set(this.security.modifyData, request.params.toString());
            let data = this.security.set(this.security.modifyData, JSON.stringify(request.body));
            
            let headers = request.headers;
            if (request.headers.get('Authorization')) {
              headers = headers.set('Authorization', this.security.set(this.security.modifyData, headers.get('Authorization')))
            }
            if (state && state.userData && !state.backendUserData) {
              const token = state.userData.id.substring(0, 16);
              params = this.security.set(token, params);
              data = this.security.set(token, data);
              if (request.headers.get('Authorization')) {
                headers = headers.set('Authorization', btoa(this.security.set(token, headers.get('Authorization'))))
              }
            } else if (state && state.backendUserData) {
              const token = state.backendUserData.id.substring(0, 16);
              params = this.security.set(token, params);
              data = this.security.set(token, data);
              if (request.headers.get('Authorization')) {
                headers = headers.set('Authorization', btoa(this.security.set(token, headers.get('Authorization'))))
              }
            } else {
              if (headers.get('Authorization'))
                headers = headers.set('Authorization', btoa(headers.get('Authorization')))
            }
            request = request.clone({
              params: new HttpParams({
                fromObject: {
                  query: btoa(params)
                }
              }),
              body: {
                data: btoa(data)
              },
              headers
            });
          }
          if (this.environment.secure && this.skipSlug.includes(slug)) {
            const params = request.params.append('internal', 'true');
            request = request.clone({
              params
            });
          }

          if(!this.environment.secure){
            const params = request.params.append('internal', 'true');
            request = request.clone({
              params
            });
          }
          return next.handle(request)
            .pipe(
              map(event => {
                if (this.environment.secure && !request.url.includes('en.json') && !this.skipSlug.includes(slug)) {
                  if (event instanceof HttpResponse) {
                    isDevMode()?console.log(JSON.parse(this.security.get(this.security.modifyData, atob(event.body.data)))):'';
                    return event.clone({
                      body: {
                        data: JSON.parse(this.security.get(this.security.modifyData, atob(event.body.data)))
                      }
                    });
                  }
                }
                return event;
              }),
              catchError((error: HttpErrorResponse) => {
                if (this.environment.secure && error.error && error.error.errors && !this.skipSlug.includes(slug)) {
                  const err = JSON.parse(this.security.get(this.security.modifyData, atob(error.error.errors)));
                  error = new HttpErrorResponse({
                    error: {
                      status: error.status,
                      error: err
                    }
                  });
                  console.log(error);
                  return throwError(error);
                }
                return throwError(error);
              })
            );
        })
      );
  }
}
