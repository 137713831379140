import { Action } from '@ngrx/store';
import { TaskInfoInterface } from '@rubicon/interface/task-info';

export enum BusinessDetailsActionTypes {
  LoadBusinessDetails = '[DashboardBusinessDetails] Load BusinessDetails',
  BusinessDetailsLoaded = '[DashboardBusinessDetails] BusinessDetails Loaded',
  BusinessDetailsLoadError = '[DashboardBusinessDetails] BusinessDetails Load Error',
  BusinessDetailsFormSubmit = '[DashboardBusinessDetails] BusinessDetails Form Submit',
  BusinessDetailsFormCompleted = '[DashboardBusinessDetails] BusinessDetails Form Completed',
  BusinessDetailsFormReset = '[DashboardBusinessDetails] BusinessDetails Form Reset',
  LoadUserDetails = '[DashboardUserDetails] Load BusinessDetails',
  LoadUserDetailsSBA = '[DashboardUserDetails] Load BusinessDetailsSBA',
  BusinessUserLoaded = '[DashboardUserDetails] BusinessDetails Loaded',
  BusinessUserLoadError = '[DashboardUserDetails] BusinessDetails Load Error',
  LoadApplicantDetails ='[DashboardBusinessDetails] Load ApplicationDetails',
  LoadSingleUserApps ='[DashboardBusinessDetails] Load Single User Apps',
  ClearSingleUserApps = '[DashboardBusinessDetails] Clear Single User Apps',
  SubmitSBAApplication = '[DashboardBusinessDetails] Submit SBA Application',
  SubmitSBAApplicationCompleted = '[DashboardBusinessDetails] Submit SBA Application Success',
  SubmitSBAApplicationFailure = '[DashboardBusinessDetails] Submit SBA Application Failure',
  SubmitSBAApplicationClear = '[DashboardBusinessDetails] Submit SBA Application clear',
  SetB2CRef = '[DashboardBusinessDetails] Set B2CRef',
};

export class SetB2CRef implements Action {
  readonly type = BusinessDetailsActionTypes.SetB2CRef;
  constructor(public payload: any) { }
}

export class LoadBusinessDetails implements Action {
  readonly type = BusinessDetailsActionTypes.LoadBusinessDetails;
  constructor(public payload: any) { }
}

export class BusinessDetailsLoadError implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessDetailsLoadError;
  constructor(public payload: any) {}
}

export class BusinessDetailsLoaded implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessDetailsLoaded;
  constructor(public payload: TaskInfoInterface) {}
}
export class BusinessDetailsFormSubmit implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessDetailsFormSubmit;

  constructor(public slug: string, public payload: any) { }
}

export class BusinessDetailsFormCompleted implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessDetailsFormCompleted;

  constructor(public payload: any) { }
}

export class BusinessDetailsFormReset implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessDetailsFormReset;

  constructor() { }
}

export class LoadUserDetails implements Action {
  readonly type = BusinessDetailsActionTypes.LoadUserDetails;
  constructor(public payload: any) { }
}
export class LoadUserDetailsSBA implements Action {
  readonly type = BusinessDetailsActionTypes.LoadUserDetailsSBA;
  constructor(public payload: any) { }
}

export class LoadApplicantDetails implements Action {
  readonly type = BusinessDetailsActionTypes.LoadApplicantDetails;
  constructor(public payload: any) { }
}

export class BusinessUserLoaded implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessUserLoaded;

  constructor(public payload: any) {}
}

export class BusinessUserLoadError implements Action {
  readonly type = BusinessDetailsActionTypes.BusinessUserLoadError;

  constructor(public payload: any) {}
}

export class LoadSingleUserApps implements Action {
  readonly type = BusinessDetailsActionTypes.LoadSingleUserApps;
  constructor(public payload: any) { }
}

export class ClearSingleUserApps implements Action {
  readonly type = BusinessDetailsActionTypes.ClearSingleUserApps;
  constructor(public payload: any) { }
}

export class SubmitSBAApplication implements Action {
  readonly type = BusinessDetailsActionTypes.SubmitSBAApplication;
  constructor(public payload: any) { }
}

export class SubmitSBAApplicationCompleted implements Action {
  readonly type = BusinessDetailsActionTypes.SubmitSBAApplicationCompleted;

  constructor(public payload: any) { }
}


export class SubmitSBAApplicationClear implements Action {
  readonly type = BusinessDetailsActionTypes.SubmitSBAApplicationClear;

  constructor() { }
}




export class SubmitSBAApplicationFailure implements Action {
  readonly type = BusinessDetailsActionTypes.SubmitSBAApplicationFailure;

  constructor(public createType : any) { }
}

export type BusinessDetailsAction =
  | LoadBusinessDetails
  | BusinessDetailsLoaded
  | BusinessDetailsLoadError
  | BusinessDetailsFormSubmit
  | BusinessDetailsFormCompleted
  | BusinessDetailsFormReset
  | LoadUserDetails
  | BusinessUserLoaded
  | BusinessUserLoadError
  | LoadApplicantDetails
  | LoadSingleUserApps
  | ClearSingleUserApps
  | SubmitSBAApplication
  | SubmitSBAApplicationCompleted
  | SubmitSBAApplicationFailure
  | SubmitSBAApplicationClear
  | SetB2CRef

export const fromBusinessDetailsActions = {
  LoadBusinessDetails,
  BusinessDetailsLoaded,
  BusinessDetailsLoadError,
  BusinessDetailsFormSubmit,
  BusinessDetailsFormCompleted,
  BusinessDetailsFormReset,
  LoadUserDetails,
  BusinessUserLoadError,
  LoadApplicantDetails,
  LoadSingleUserApps,
  ClearSingleUserApps,
  SubmitSBAApplication,
  SubmitSBAApplicationCompleted,
  SubmitSBAApplicationFailure,
  SubmitSBAApplicationClear,
  SetB2CRef
};

