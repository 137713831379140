import { AppComponent } from "./app.component";
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandler } from '@angular/core'

// Core modules imports
import { BrowserModule, Title, Meta, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Optional } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Ngrx imports
import { StoreModule, MetaReducer, META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { NxModule } from '@nrwl/angular';

import * as fromRoot from './+state/app.reducers';

// Third party imports
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';


// Environment file import
import { environment } from '../environments/environment';

// Library modules import
import { CustomerPortalAuthLayoutModule } from '@rubicon/customer-portal/auth-layout';
import { CustomerPortalSiteLayoutModule } from '@rubicon/customer-portal/site-layout'
import { CustomerPortalUiKitModule } from '@rubicon/customer-portal/ui-kit';
import { SharedModule } from '@rubicon/shared';
import { AppStateFacade } from './+state/app.facade';
import { storageMetaReducer, updateRootState } from './+state/app-meta.reducer';
import { CommonService } from '@rubicon/utils_custom';
import { DatePipe } from '@angular/common';
// import * as cssJson  from '../assets/json/css.json';
import { APP_ROUTES, APP_STEP,CONSTANTS, INVALID_ROUTES, STATIC_URL } from '@affiliate-assets-base/*';
import { EMAIL_TEMPLATE } from "@affiliate-assets-email-templates/*";
import { CONFIG } from "@affiliate-tenant-config/*";
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TaskGuard } from './guard/task.guard';
import { AuthLazyGuard } from './guard/auth-lazy.guard';
import { UniqueInputEventPlugin, UNIQUE_INPUT_EVENT_PLUGIN_CONFIG } from '@rubicon/utils_custom';
import { ToastrModule } from 'ngx-toastr';
import { AccountDetailsFacade } from 'libs/affiliate-portal/account-directory/src/lib/+state/account-details.facade';
import { ErrorHandler as BgErrorHandler } from './bugsnag.handler';
// import { PdfViewerModule} from 'ng2-pdf-viewer';
declare function require(name: string);
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function getMetaReducers(storageService: CommonService): MetaReducer<any> {
  return storageMetaReducer(storageService);
}

export function getproductConfigJson() {
  return require(`../assets/${environment.client_folder}/json/product-config.json`);
}

export function getCssJson() {
  const base_css =  require(`../assets/base/json/css.json`);
  const client_css = require(`../assets/${environment.client_folder}/json/css.json`);
  
  // merge base and client file into one. client file will override same key present in base file.
  const merge = { ...base_css, ...client_css};
  return merge;
}

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import Bugsnag from '@bugsnag/js';
import { UiKitModule } from '@rubicon/libraries/core-utils/ui-kit';


if(environment && environment['bugsnagKey']) {
  Bugsnag.start(environment && environment['bugsnagKey'] ? environment['bugsnagKey']:'NA' )
}
function errorHandlerFactory() {
  return environment && environment['bugsnagKey'] ? (new BugsnagErrorHandler()): new BgErrorHandler(); 
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    UiKitModule,
    // PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule.forRoot(),
    AppRoutingModule,
    NxModule.forRoot(),
    StoreModule.forRoot(
      { [fromRoot.APP_KEY]: fromRoot.appReducer },
      { metaReducers: [updateRootState],
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }
    ),
    CustomerPortalAuthLayoutModule,
    CustomerPortalSiteLayoutModule,
    CustomerPortalUiKitModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    ToastrModule
  ],
  providers: [
    AppStateFacade,
    AccountDetailsFacade,
    Title,
    Meta,
    {
      provide: META_REDUCERS, multi: true, deps: [CommonService], useFactory: getMetaReducers
    },
    // { provide: 'cssList', useValue: (getCssJson() as any).default },
    { provide: 'cssList', useFactory: getCssJson },
    { provide: 'APP_ROUTES', useValue: APP_ROUTES },
    { provide: 'APP_STEP', useValue: APP_STEP },
    { provide: 'CONSTANTS', useValue: CONSTANTS },
    { provide: 'INVALID_ROUTES', useValue: INVALID_ROUTES},

    { provide: 'environment', useValue: environment },
    { provide: 'env', useValue: environment },
    { provide: 'constants', useValue: CONSTANTS },
    { provide: 'productConfig', useFactory: getproductConfigJson },
    { provide: 'email_templates', useValue: EMAIL_TEMPLATE},
    { provide: 'config', useValue: CONFIG },
    { provide: 'urls', useValue: STATIC_URL },


    { provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    environment.GTM_CODE ? { provide: 'googleTagManagerId', useValue: environment.GTM_CODE}: [],
    window.navigator.msSaveOrOpenBlob ?{ provide: UNIQUE_INPUT_EVENT_PLUGIN_CONFIG, useValue: {} }:[],
    window.navigator.msSaveOrOpenBlob ?{
      provide: EVENT_MANAGER_PLUGINS,
      useClass: UniqueInputEventPlugin,
      multi: true,
      deps: [[new Optional(), UNIQUE_INPUT_EVENT_PLUGIN_CONFIG]]
    }:[]

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
