import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";

@Component({
  selector: "rubicon-drag-and-drop",
  templateUrl: "./drag-and-drop.component.html",
  styleUrls: ["./drag-and-drop.component.scss"],
})

export class DragAndDropComponent {
  @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileRemoved = new EventEmitter<void>();
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  allowedFileTypes = '.pdf';
  uploadFile!: File | null;
  errorMessage = '';

  handleChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      this.validateAndSetFile(file);
    }
  }

  handleFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];

    if (file) {
      this.validateAndSetFile(file);
    }
  }

  private validateAndSetFile(file: File): void {
    if (file.type === 'application/pdf') {
      this.errorMessage = '';
      this.uploadFile = file;
      this.fileUploaded.emit(file);
    } else {
      this.errorMessage = 'Error: Uploaded document must be a PDF';
      this.uploadFile = null;
    }
  }

  handleRemovesFile(): void {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
    this.uploadFile = null;
    this.fileRemoved.emit(); 
  }

  preventDefault(event: DragEvent): void {
    event.preventDefault();
  }
}
