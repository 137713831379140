<div class="container vh-100 vw-100 d-flex flex-column justify-content-center align-items-center">
  <div class="drag-drop-zone position-relative d-flex flex-column justify-content-center align-items-center"
    (dragover)="preventDefault($event)" (drop)="handleFileDrop($event)" style="width: 100%; height: 200px;">
    <input #fileInput type="file" (change)="handleChange($event)" [accept]="allowedFileTypes" class="d-none" />
    <img src="assets/base/images/Frame 3466215.jpg" alt="">
    <p class="text-center mb-0">
      Drag and Drop <br>or <a href="javascript:void(0);" (click)="fileInput.click()"
        class="text-decoration-underline">Upload</a>
    </p>
  </div>
</div>

<div *ngIf="errorMessage" class="error-msg">
  {{ errorMessage }}
</div>

<div *ngIf="uploadFile" class="file-preview mt-2">
  <!-- <div> -->
    <img src="assets/base/images/file-pdf.jpg" alt="">
    <span class="fw-bold">{{ uploadFile.name }}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="handleRemovesFile()">
      <span aria-hidden="true">&times;</span>
    </button>
  <!-- </div> -->
</div>