import { map, switchMap, tap,  } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { TaskInfoService } from '../api/task-info.service';
import { Observable, of, Subject } from 'rxjs';
import { CONSTANTS, SOCK_EVENTS } from '@affiliate-assets-base/*';
import * as io from "socket.io-client";
import * as moment from 'moment';
import { productTypeMap } from 'libs/affiliate-portal/dashboard/src/lib/constants';


@Injectable({
  providedIn: 'root',
})

export class NotificationService {
  public updateNotificationSub =  new Subject<boolean>();
  public showVideoNotification = new Subject<any>();
  public showLeadNotification = new Subject<any>();
  public showViewApplication = new Subject<any>();
  public openUVModal = new Subject<any>();

  openUVModal$ = this.openUVModal.asObservable();

  socket :io.Socket;
  constructor( private taskInfoService: TaskInfoService,  @Inject('environment') private environment ) { 

  }
  
  Connect(options?){
    let url = location.protocol+this.environment.orchUrl ;
    this.socket = io.io(url, {
      transports:['websocket'],
      reconnectionAttempts: 5
    });
  }

  getMessage(sessionId) {
    this.socket.on('connected', (data)=>{
      console.log('auth connection established');
      this.socket.emit( 'join_session',  sessionId)
    });

    this.socket.on('session_err',()=>{
      console.log('session err')
    })  
  }

  getApplicationUpdate() {
    return new Observable((observer) => {
      this.socket.on(SOCK_EVENTS.APPLICATION_UPDATE, (data:any) =>{
        if(data._notification){
           observer.next(data._notification);
        }
      })
    });
  }


  getNotifications(payload:any){
    payload = {...payload,  slug: 'notification' };
    return this.taskInfoService.getTaskInfo({...payload}).pipe(
      switchMap(resp=>{
        if(resp 
          && resp.response_data 
          && resp.response_data.get_notifications 
          && resp.response_data.get_notifications.data
          && resp.response_data.get_notifications.data.data){
            const {data, unread_count, total } = resp.response_data.get_notifications.data.data;
            return of({ notifications: data, unread_count, total});
        }else{
          return of({notifications:[], unread_count:0, total:0})
        }
      })
    )
  }

  notificationBuilder(notificationData) {
    let b2c_status_map = CONSTANTS.B2C_TO_CPA_STATUS_MAP;
    let b2cToCpaStatusMapSBA = CONSTANTS.B2C_TO_CPA_STATUS_MAP_SBA;
    return notificationData.map(notification => {
      switch(notification.type) {
        case 'feature_video':
          if(notification.meta_data.feature_name == 'DTC'){
              notification.title = "Earn financing advisory revenue the easy way with these new Portal features. Now available in your account.";
              notification.header = "New Features Released: Shareable Application Page | Prequalification Estimates | Client Email Messaging";
          }
          break;
        case 'uw_note':
          let caseProductName = '';
          if(notification.meta_data.offered_product_type) {
            caseProductName = productTypeMap[notification.meta_data.offered_product_type];
          } else if(notification.meta_data.product_type) {
            caseProductName = productTypeMap[notification.meta_data.product_type];
          }
          notification.header = 'Underwriter Note Added';
          notification.title = `The Underwriter note was left for a ${caseProductName} application for ${(notification.meta_data && notification.meta_data.business_name) ? notification.meta_data.business_name : ''} APP${notification.app_id}.`;
          break;  
        case 'e-sign_complete':
          notification.header = 'E-Sign Completed';
          notification.title = `The Esign request has been completed for a ${notification.meta_data.product_type ? productTypeMap[notification.meta_data.product_type] : ''} application for ${(notification.meta_data && notification.meta_data.business_name) ? notification.meta_data.business_name : ''} APP${notification.app_id}.`;
          break; 
        case 'e-sign_decline':
          notification.header = 'E-Sign Declined';
          notification.title = `The Esign request has been denied for a ${notification.meta_data.product_type ? productTypeMap[notification.meta_data.product_type] : ''} application for ${(notification.meta_data && notification.meta_data.business_name) ? notification.meta_data.business_name : ''} APP${notification.app_id}.`;
          break; 
        case 'offer_generated':
          notification.header = 'Application Status Updated'
          notification.title =  `You've recieved new offer(s) for the ${notification.meta_data.product_type ? productTypeMap[notification.meta_data.product_type] : ''}  application for ${notification.meta_data.business_name}. \n Click 'View Offer' on the application to view and respond to the offer(s).`;
          break;
        case 'app_status_update':
          let statusProductName = '';
          notification.header = 'Application Status Updated';
          if(notification.meta_data && notification.meta_data.product_type === CONSTANTS.APP_TYPE.sba){
            statusProductName = 'SBA (7a)';
            notification.title = `The status of ${statusProductName} application ${notification.app_id ? 'APP'+notification.app_id : '' } for ${(notification.meta_data && notification.meta_data.business_name) ? notification.meta_data.business_name : ''} has been updated to “${b2cToCpaStatusMapSBA[notification.meta_data.app_status.toLowerCase()] }”`
          } else {
            if(notification.meta_data.offered_product_type) {
              statusProductName = productTypeMap[notification.meta_data.offered_product_type];
            } else if(notification.meta_data.product_type) {
              statusProductName = productTypeMap[notification.meta_data.product_type];
            }
            notification.title = `The status has been updated to “${b2c_status_map[notification.meta_data.app_status.toLowerCase()] }” for a ${statusProductName} application for ${(notification.meta_data && notification.meta_data.business_name) ? notification.meta_data.business_name : ''} APP${notification.app_id}.`;
          }
          break; 
        case 'offered_product_type':
          notification.header = `${notification.meta_data.client_name} - Loan Changed to ${notification.meta_data.offered_product_type ? productTypeMap[notification.meta_data.offered_product_type] : ''}`;
          notification.title = `The loan application (${notification.app_id}) for ${notification.meta_data.client_name} has been revised to ${notification.meta_data.offered_product_type ? productTypeMap[notification.meta_data.offered_product_type] : ''} following a review.`;
          break; 
        case 'plan_renew_success':
          // const subscriptionDate = notification.meta_data ? notification.meta_data.subscribed_on : null;
          // let expiryDate: any = '_____';
          // if(subscriptionDate) {
          //   expiryDate = moment(new Date(subscriptionDate)).add(1, 'year').format('MM/DD/YYYY');
          // }
          notification.header = 'Congratulations!';
          notification.title = `Your plan was successfully renewed.`;
          break;
        case 'payment-reminder':
          notification.header = 'Payment Reminder';
          notification.title = `PPP Deferral Period Ending for ${notification.meta_data.business_name} on ${notification.meta_data.repayment_date_string}. (AppID: ${notification.app_id})`;
          break;
        case 'user_request':
          notification.header = `New user request`;
          notification.title = `${notification.meta_data.first_name} ${notification.meta_data.last_name} (${notification.meta_data.phone_number}) requesting access to portal. Verify and create a profile from user management. See email for more information.`;
          break;
        case 'app_creation':
          notification.header = `New application created for ${notification?.meta_data?.client_name} via ${notification?.meta_data?.source}`;
          notification.title = `A new application has been created for ${notification?.meta_data?.client_name} via ${notification?.meta_data?.source}.`
          break;
        case 'leads_notii':
          notification.header = 'Your Results Are Ready!';
          notification.title = `Your bulk refer to Funding Specialist is ready. View Results.`
          break;
        default:
          notification.title = notification.type
      }
      return notification;
    });
  }

  updateReadStatus(notificationId: string, readStatus: boolean){
    let _payload = {
      id: notificationId,
      is_read: readStatus,
    }
    
    return this.taskInfoService.saveTaskInfo({ 
      slug: 'update_notification'
    }, _payload);
  }

  getProductName(notification) {
    if(notification.meta_data.app_type_data) {
      return notification.meta_data.app_type_data;
    } else if(notification.meta_data.offered_product_type) {
      return productTypeMap[notification.meta_data.offered_product_type];
    } else if(notification.meta_data.product_type) {
      return productTypeMap[notification.meta_data.product_type];
    }
  }
}
