import { Injectable } from '@angular/core';

import { select, Store,Action } from '@ngrx/store';
import { PDFParseAppPartialState } from './pdf-parse-app.reducer';

import { PdfParseAppQuery } from './pdf-parse-app.selectors'

@Injectable()
export class PdfParseAppFacade {
  loaded$ = this.store.pipe(select(PdfParseAppQuery.getLoaded));
  allPdfParseApp$ = this.store.pipe(
    select(PdfParseAppQuery.getAllPdfParseApp)
  );
  
  submitPdfParseApp$ = this.store.pipe(select(PdfParseAppQuery.getPdfParseAppResponseState));

  constructor(private store: Store<PDFParseAppPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
  
}
