import { Injectable } from '@angular/core';

import { select, Store,Action } from '@ngrx/store';
import { BusinessDetailsPartialState } from './business-details.reducer';
import { businessDetailsQuery } from './business-details.selectors';

@Injectable()
export class BusinessDetailsFacade {
  loaded$ = this.store.pipe(select(businessDetailsQuery.getLoaded));
  allBusinessDetails$ = this.store.pipe(
    select(businessDetailsQuery.getAllBusinessDetails)
  );
  submitBusinessDetails$ = this.store.pipe(select(businessDetailsQuery.getBusinessDetailsResponseState));
  getApplications$ = this.store.pipe(select(businessDetailsQuery.getApplications));
  getViewApplications$ = this.store.pipe(select(businessDetailsQuery.getViewApplications));
  getSelectedUserApps$ = this.store.pipe(select(businessDetailsQuery.getSelectedUserApps))
  getSelectedLastSBAApp$ = this.store.pipe(select(businessDetailsQuery.getSelectedLastSBAApp))
  constructor(private store: Store<BusinessDetailsPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
  
}
