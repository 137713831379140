import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AICPA_DASHBOARD_FEATURE_KEY,
  BusinessDetailsState
} from './business-details.reducer';

// Lookup the 'BusinessDetails' feature state managed by NgRx
const getBusinessDetailsState = createFeatureSelector<BusinessDetailsState>(
  AICPA_DASHBOARD_FEATURE_KEY
);

const getLoaded = createSelector(
  getBusinessDetailsState,
  (state: BusinessDetailsState) => state.loaded
);
const getError = createSelector(
  getBusinessDetailsState,
  (state: BusinessDetailsState) => state.error
);

const getAllBusinessDetails = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState, isLoaded) => {
    return (!state.response && isLoaded) ? state : null;
  }
);
const getBusinessDetailsResponseState = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState) => {
    return state.response;
  }
);

const getApplications = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState) => {
    return { users:state.users, total_users: state.total_users, counts: state.counts , b2cRefIds:state.b2cRefIds,user_ids:state.user_ids, b2cForgivRefIds:state.b2cForgivRefIds,b2cTermLoanRefIds:state.b2cTermLoanRefIds,b2cSbaLoanRefIds:state.b2cSbaLoanRefIds };
  }
);

const getViewApplications = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState) => {
    return { appMap:state.appMap,b2cRefIds:state.b2cRefIds, b2cForgivRefIds:state.b2cForgivRefIds,b2cTermLoanRefIds:state.b2cTermLoanRefIds,b2cSbaLoanRefIds:state.b2cSbaLoanRefIds};
  }
);

const getSelectedUserApps = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState) => {
    return {selectedUserApps: state.selectedUserApps, userAppsb2cRefIds: state.userAppsb2cRefIds, userAppsForgivRefIds: state.userAppsForgivRefIds, userAppsTermLoanRefIds: state.userAppsTermLoanRefIds,userAppsSbaLoanRefIds: state.userAppsTermLoanRefIds };
  }
);


const getSelectedLastSBAApp = createSelector(
  getBusinessDetailsState,
  getLoaded,
  (state: BusinessDetailsState) => {
    return  { is_submitted:  state.is_submitted, submitAppPayload: state.submitAppPayload,createType: state.createType }
  }
);


export const businessDetailsQuery = {
  getLoaded,
  getError,
  getAllBusinessDetails,
  getBusinessDetailsResponseState,
  getApplications,
  getViewApplications,
  getSelectedUserApps,
  getSelectedLastSBAApp
};
