import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { addProductType, CommonService, setIsPdfApp, setParsedPdfData, TaskInfoService } from '@rubicon/utils_custom';
import { of, Subject, Subscription, timer } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SetPdfParseFormLoaded } from 'libs/term-loan/pdf-details/src/lib/+state/pdf-parse-app.actions';
import { PdfParseAppFacade } from 'libs/term-loan/pdf-details/src/lib/+state/pdf-parse-app.facade';



@Component({
  selector: 'rubicon-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.scss']
})
export class UploadPdfComponent implements OnInit {
  upload_file_config = {
    slug : 'upload-pdf-for-parsing',
  }
  upload_config = {
    slug : 'upload_docs_bckdUser',
    doc_type_key : 'miscellaneous',
    doc_type_id : '6780f175f5f7c7e2962650e6'
  }
  parseError: Boolean = false;
  parsingStatus: String = "";
  parsedPdfData: any;
  callFunction: any;
  requestId: String = "";
  doc: any;
  backendUser: any;
  backendUserId: any;
  apptype: string;
  pollingSubscription!:Subscription;
  private stop$ = new Subject<void>(); 
  product_type: string;

  constructor(
    private taskInfoService: TaskInfoService,
    private store: Store<any>,
    private common: CommonService,
    private router: Router,
    private pdfParseAppFacade: PdfParseAppFacade,
  ) { }

  ngOnInit(): void {
    this.store.pipe(
      select('app'),
      take(1)
    ).subscribe((res) => {
      this.backendUser = res?.backendUserData?.user
      this.backendUserId = res?.backendUserData?.user?.id
      this.apptype = res?.clientData?.app_type;
      this.product_type = res?.clientData?.app_type_id;
    })
  }

  @Input() isModalVisible: boolean = false; // Controls modal visibility
  @Output() closeModal = new EventEmitter<void>(); // Emits when the modal is closed
  @Output() startManualApplication = new EventEmitter<void>(); // Emits when manual application is started

  uploadedFile: File | null = null;

  handleFileUpload(file: File) {
    this.uploadedFile = file;
  }

  fetchParsedPdfData(reqId: string){
    this.taskInfoService.saveTaskInfo({slug: "fetch-parsed-pdf-data"}, {"request_id": reqId}).subscribe((res)=>{
      if(res && res.fetch_parsed_pdf_data && res.fetch_parsed_pdf_data.data){
        this.parsingStatus = res.fetch_parsed_pdf_data.data.status
      }
      if(res && res.fetch_parsed_pdf_data.status==400 && res.fetch_parsed_pdf_data.errors){
        clearInterval(this.callFunction);
        this.parsingStatus = res.fetch_parsed_pdf_data.errors.status;
        this.parseError = true;
        this.common.stopLoad();
      }
      if(this.parsingStatus=='Complete'){
          let respData =  res.fetch_parsed_pdf_data.data.data[0];
          this.updateState(respData);
          clearInterval(this.callFunction);
          this.common.popToast('success', 'Success', 'PDF Parsed successfully.');
          this.close();
        }
      })
    }

  updateState(response: any | null){
    if(this.doc){
      this.parsedPdfData = {...this.parsedPdfData, doc: this.doc};
    }
    if(this.parsingStatus=='Complete' && response){
      this.parsedPdfData = {...this.parsedPdfData, ...response};
    }
    if(this.doc && this.parsingStatus=='Complete'){
      this.common.stopLoad();
      this.store.dispatch(setParsedPdfData({parsedData: null}));
      this.pdfParseAppFacade.dispatch(new SetPdfParseFormLoaded({...this.parsedPdfData}));
      this.store.dispatch(setIsPdfApp({isPdfApp: true}));
      this.taskInfoService.saveTaskInfo({
        slug: 'save-parsed-pdf-data'
      }, {
        user_id: this.backendUserId,
        response_to_be_saved: this.parsedPdfData,
        ref_id: this.requestId
      })
      .subscribe(data => {
        setTimeout(()=>{
          if(this.apptype == 'term_loan'){
            this.router.navigate(['/term-loan/pdf-info']);
          }else if(this.apptype == 'working_capital'){
            this.router.navigate(['/revenue-based-financing/pdf-info']);
          }else if(this.apptype == 'ertc'){
            this.router.navigate(['/ertc/pdf-info']);
          }
        },0)
      });
    }
  }

  saveDataAndRedirect(parsed_data:any){
    this.common.stopLoad();
    this.store.dispatch(setParsedPdfData({parsedData: null}));
    let parsed_payload = {...parsed_data?.fetch_parsed_pdf_data?.data?.data[0], doc: this.doc };
    this.pdfParseAppFacade.dispatch(new SetPdfParseFormLoaded(parsed_payload));
    this.store.dispatch(setIsPdfApp({isPdfApp: true}));
    this.store.dispatch(addProductType({ productType: this.product_type }));
    
    this.taskInfoService.saveTaskInfo({
      slug: 'save-parsed-pdf-data'
    }, {
      user_id: this.backendUserId,
      response_to_be_saved: parsed_payload,
      ref_id: this.requestId
    })
    .subscribe(data => {
      setTimeout(()=>{
        if(this.apptype == 'term_loan'){
          this.router.navigate(['/term-loan/pdf-info']);
        }else if(this.apptype == 'working_capital'){
          this.router.navigate(['/revenue-based-financing/pdf-info']);
        }else if(this.apptype == 'ertc'){
          this.router.navigate(['/ertc/pdf-info']);
        }
      },0)
    });

  }

  setParseError(){
    this.parseError = true;
    this.common.stopLoad();
  }


  isParsingComplete(response:any){
    let parse_status = response?.fetch_parsed_pdf_data?.data?.status;
    if(parse_status && parse_status.toLowerCase() == 'complete'){ 
      this.common.popToast('success', 'Success', 'PDF Parsed successfully.');
      this.close()
      this.common.stopLoad();
      return true;
    }else if(response?.fetch_parsed_pdf_data?.status == 400 && response?.fetch_parsed_pdf_data?.errors){
      this.stop$.next();
      this.setParseError();
    }else{
      return false;
    }
  }

  fetchParseProgress(request_id) {
    const stopAfterTwoMinutes = timer(2 * 60 * 1000); 
    this.stop$ = new Subject(); 
    let isCompleted = false;     
    timer(0, 10 * 1000)
      .pipe(
        switchMap(() => {
          if (isCompleted) {
            return of(null); 
          }
          return this.taskInfoService.saveTaskInfo({ slug: "fetch-parsed-pdf-data" }, { request_id });
        }),
        tap((response) => {
          if (this.isParsingComplete(response)) {
            isCompleted = true;
            this.stop$.next(); 
            this.saveDataAndRedirect(response); 
          }
        }),
        takeUntil(this.stop$.pipe(takeUntil(stopAfterTwoMinutes))) 
      )
      .subscribe({
        next: (response) => {
          if (this.isParsingComplete(response)) {
            isCompleted = true;
            this.stop$.next();  
            this.saveDataAndRedirect(response);
          }
        },
        error: (err) => {
          this.stop$.next();  
          this.setParseError(); 
        },
        complete: () => {
          this.common.stopLoad(); 
        }
      });
  }

  
  submitApplication() {
    let parse_pdf_response:any = {};
    const formData: FormData = new FormData();
    formData.append('file', this.uploadedFile);
    formData.append('type', 'file');
    formData.append('document_type', 'broker_pdf');

    this.taskInfoService.saveTaskInfo({slug: this.upload_file_config.slug}, formData)
    .pipe(
      switchMap((res: any)=>{
        if(res 
          && res.pdf_upload_for_parsing 
          && res.pdf_upload_for_parsing.data 
          && res.pdf_upload_for_parsing.data.request_id
        ){
          parse_pdf_response = res;
          const formData: FormData = new FormData();
          formData.append('file', this.uploadedFile);
          formData.append('filename', this.uploadedFile.name);
          formData.append('filekey', 'data');
          formData.append('doc_type_key', this.upload_config.doc_type_key);
          formData.append('doc_type_id', this.upload_config.doc_type_id);
          formData.append('user_id', this.backendUserId);
          formData.append('parse_response', 'true');

          const responseToSave = { 
            name: this.uploadedFile.name, 
            size: this.uploadedFile.size, 
            doc_type_key: this.upload_config.doc_type_key, 
            doc_type_id: this.upload_config.doc_type_id 
          };

          formData.append('response_to_be_saved', JSON.stringify(responseToSave));
          
          return this.taskInfoService.saveTaskInfo({ slug: this.upload_config.slug }, formData);
        }else if(res && res.pdf_upload_for_parsing.status == 400){
          return of({error: true});
        }
      })
    ).subscribe(ref_response => {
      if(ref_response.error){
        this.setParseError();
      }

      if(ref_response?.bckduser_doc_upload_reference?.data?.data){
        this.doc = ref_response?.bckduser_doc_upload_reference?.data?.data;
      }

      if(parse_pdf_response?.pdf_upload_for_parsing?.data?.request_id){
        this.requestId = parse_pdf_response?.pdf_upload_for_parsing.data.request_id;
        this.common.startLoad();
        this.fetchParseProgress(this.requestId);
      }
    })
  }

  close() {
    this.closeModal.emit();
  }

  startManual() {
    this.store.dispatch(setIsPdfApp({isPdfApp: false}));
    this.startManualApplication.emit();
  }

  handleFileRemoval(){
    this.uploadedFile = null;
    clearInterval(this.callFunction);
  }
}
