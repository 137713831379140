import {
  BusinessDetailsAction,
  BusinessDetailsActionTypes
} from './business-details.actions';

export const AICPA_DASHBOARD_FEATURE_KEY = 'affiliateDashboard';

/**
 * Interface for the 'BusinessDetails' data used in
 *  - BusinessDetailsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface BusinessDetailsState {
  loaded: boolean; // has the LendingNeeds list been loaded
  error?: any; // last none error (if any)
  loading: boolean;
  taskInfo: any;
  response?: any;
  errors?: any;
  formData?: any;
  users?:any;
  total_users?:any;
  counts?:any;
  b2cRefIds?:Array<any>;
  b2cForgivRefIds?:Array<any>;
  b2cTermLoanRefIds?:Array<any>;
  b2cSbaLoanRefIds?:Array<any>;
  user_ids?:Array<any>;
  appMap?: any;
  selectedUserApps?: Array<any>;
  userAppsb2cRefIds?: Array<string>;
  userAppsForgivRefIds?: Array<string>;
  userAppsTermLoanRefIds?: Array<string>;
  submitAppPayload?: Array<any>;
  is_submitted? : boolean;
  createType? : string;
  b2cRef: any;
}

export interface BusinessDetailsPartialState {
  readonly [AICPA_DASHBOARD_FEATURE_KEY]: BusinessDetailsState;
}

export const initialState: BusinessDetailsState = {
  loaded: false,
  loading: false,
  taskInfo: null,
  response: null,
  users: [],
  total_users: 0,
  counts: {ppp2:0, forgiveness: 0},
  user_ids :[],
  b2cRef: null
};

export function reducer(
  state: BusinessDetailsState = initialState,
  action: BusinessDetailsAction
): BusinessDetailsState {
  switch (action.type) {

    case BusinessDetailsActionTypes.SetB2CRef: 
      {
        return {
          ...state,
          b2cRef: {
            ...state.b2cRef,
            ...action.payload
          }
        };
      }
      break;
    
    case BusinessDetailsActionTypes.LoadBusinessDetails:
      {
        state = {
          ...state,
          response: null,
          loading: true
        };
      }
      break;
    case BusinessDetailsActionTypes.BusinessDetailsLoaded:
      {
        state = {
          ...state,
          taskInfo: action.payload,
          response: null,
          loaded: true,
          loading: false
        };
      }
      break;
    case BusinessDetailsActionTypes.BusinessDetailsFormSubmit: {
      state = {
        ...state,
        loaded: false,
        loading: true,
        formData: action.payload
      };
    }
      break;
    case BusinessDetailsActionTypes.BusinessDetailsFormCompleted: {
      state = {
        ...state,
        loaded: true,
        loading: false,
        response: action.payload,
        taskInfo:null
      };
    }
      break;
      case BusinessDetailsActionTypes.BusinessDetailsFormReset: {
        state = {
          ...state,
          loaded: false,
          loading: false,
          response: null,
          taskInfo:null
        };
      }
        break;
    case BusinessDetailsActionTypes.BusinessDetailsLoadError: {
      state = {
        ...state,
        loaded: false,
        loading: false,
        errors: action.payload
      };
      break;
    }
    case BusinessDetailsActionTypes.LoadUserDetails:
      {
        state = {
          ...state,
          response: null,
          loading: true
        };
        break;
      }
    case BusinessDetailsActionTypes.BusinessUserLoaded:
      {
        state = {
          ...state,
          users: [...action.payload.users],
          total_users: action.payload.total_users,
          counts: action.payload.counts,
          b2cRefIds:action.payload.b2cRefIds,
          b2cForgivRefIds: action.payload.b2cForgivRefIds,
          b2cTermLoanRefIds: action.payload.b2cTermLoanRefIds,
          b2cSbaLoanRefIds: action.payload.b2cSbaLoanRefIds,
          response: null,
          loading: true,
          user_ids:action.payload.user_ids
        };
        break;
      }
    case BusinessDetailsActionTypes.LoadApplicantDetails :
      {
      state = {
        ...state,
        appMap: action.payload.appMap,
        b2cRefIds:action.payload.b2cRefIds,
        b2cForgivRefIds:action.payload.b2cForgivRefIds,
        b2cTermLoanRefIds: action.payload.b2cTermLoanRefIds,
        b2cSbaLoanRefIds: action.payload.b2cSbaLoanRefIds
      };
      break;
    }

    case BusinessDetailsActionTypes.LoadSingleUserApps:
      {
        state = {
          ...state,
          selectedUserApps: action.payload.userApps ? action.payload.userApps : [],
          userAppsb2cRefIds: action.payload.userAppsb2cRefIds,
          userAppsForgivRefIds: action.payload.userAppsForgivRefIds,
        };
        break;
      } 
      
    case BusinessDetailsActionTypes.ClearSingleUserApps:
      {
        state = {
          ...state,
          selectedUserApps: undefined,
          userAppsb2cRefIds: undefined,
          userAppsForgivRefIds: undefined,
        };
        break;
      }

   case BusinessDetailsActionTypes.SubmitSBAApplication:
      {
        state = {
          ...state,
          response: false,
          loading: true
        };
        break;
      }  
     
    case BusinessDetailsActionTypes.SubmitSBAApplicationCompleted:
      {
        state = {
          ...state,
          submitAppPayload: action.payload,
          is_submitted: true
        };
        break;
      } 
      
      case BusinessDetailsActionTypes.SubmitSBAApplicationFailure:
      {
        state = {
          ...state,
          createType: action.createType,
          submitAppPayload: undefined,
          is_submitted: false
        };
        break;
      } 

    case BusinessDetailsActionTypes.SubmitSBAApplicationClear:
      {
        state = {
          ...state,
          createType: null,
          submitAppPayload: undefined,
          is_submitted: false
        };
        break;
      } 
    
  }
  return state;
}
