import { createFeatureSelector, createSelector } from '@ngrx/store';
import { 
  PDP_PARSE_APP_FEATURE_KEY, 
  PDFParseAppState 
} from './pdf-parse-app.reducer';


// Lookup the 'BusinessStructure' feature state managed by NgRx
const getPDFParseAppState = createFeatureSelector<PDFParseAppState>(
  PDP_PARSE_APP_FEATURE_KEY
);

const getLoaded = createSelector(
  getPDFParseAppState,
  (state: PDFParseAppState) => state.loaded
);
const getError = createSelector(
  getPDFParseAppState,
  (state: PDFParseAppState) => state.error
);

const getAllPdfParseApp = createSelector(
  getPDFParseAppState,
  getLoaded,
  (state: PDFParseAppState, isLoaded) => {
    
    return state
    // return (!state.response && isLoaded) ? state : null;
  }
);
const getPdfParseAppResponseState = createSelector(
  getPDFParseAppState,
  getLoaded,
  (state: PDFParseAppState) => {
    return state.response;
  }
);

export const PdfParseAppQuery = {
  getLoaded,
  getError,
  getAllPdfParseApp,
  getPdfParseAppResponseState
};
