<div *ngIf="!parseError">
    <div class="modal-header2">
        <h4 class="modal-title pull-left font18">Would you like to upload a PDF Application?</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p>
            Upload a PDF of your loan application to streamline your application process. Once you submit, we'll parse
            the
            PDF and autofill your application details.
        </p>

        <div class="noteText mt-1 mb-1">
            <span class="note">Note:</span> PDF parsing may take up to two minutes.
        </div>

        <rubicon-drag-and-drop (fileUploaded)="handleFileUpload($event)"
            (fileRemoved)="handleFileRemoval()"></rubicon-drag-and-drop>

        <div class="text-right mt-4">
            <button class="btn btn-secondary btn-sm" (click)="startManual()">
                Complete Application Manually
            </button>

            <button class="btn btn-primaryDark btn-sm" [disabled]="!uploadedFile" (click)="submitApplication()">
                Submit
            </button>
        </div>
    </div>
</div>

<div *ngIf="parseError">
    <div class="modal-header2">
        <h4 class="modal-title pull-left font18 error">Unable to parse PDF</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body vw-100 vh-100">
        <p class="mb-5em">
            We were unable to parse the PDF application you have uploaded. Please ensure that the uploaded document is not scanned. Try uploading the PDF again, or complete the client's application manually.
        </p>
        <div class="text-right mt-4">
            <button class="btn btn-secondary btn-sm" (click)="startManual()">
                Complete Manually Instead
            </button>

            <button class="btn btn-primaryDark btn-sm" (click)="parseError=false; uploadedFile=null;">
                Try Again
            </button>
        </div>
    </div>
</div>