import { ActionReducer, Action } from '@ngrx/store';
import { CommonService } from '@rubicon/utils_custom';
import * as _ from 'lodash';

let onInit = true; // after load/refresh…

// the key for the local storage.
const localStorageKey = 'state';

export function storageMetaReducer<S, A extends Action = Action>(storageService: CommonService) {
    return (reducer: ActionReducer<S, A>) => {
        return (state: S, action: A): S => {
            // reduce the nextState.
            const nextState = reducer(state, action);
            // init the application state.
            if (onInit) {
                onInit = false;
                const savedState = storageService.getDataFromStorage(localStorageKey);
                return _.merge(nextState, savedState);
            }
            // save the next state to the application storage.
            const stateToSave = nextState;
            if (action.type === '[UIKITSTATE] Logout') {
                state = undefined;
                storageService.deleteDataFromStorage(localStorageKey);
                return reducer(state, action);
            }
            storageService.storeData(localStorageKey, stateToSave);
            return nextState;
        };
    }
}

export function updateRootState(reducer) {
    return (state, action) => {
        switch (action.type) {
            case '[UtilState] Add Next Task':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        nextTask: action.nextTask
                    }
                };
                break;
            case '[UtilState] Add User Details':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        userData: action.userData,
                        review: null
                    },
                };
                break;
            case '[UtilState] Add Client Details':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        clientData: action.clientData,
                        review: null
                    },
                };
                break;
            case '[UtilState] Add App ID':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        appID: action.appID
                    }
                };
                break;
            case '[UtilState] Add Business ID':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        businessID: action.businessID
                    }
                };
                break;
            case '[UtilState] App on Review':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        review: action.reviewTask
                    }
                };
                break;

            case '[UtilState] Update OTP Email Address':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        otp_email: action.email_address
                    }
                };
                break;
            case '[UIKITSTATE] Logout':
                state = {
                    app: {}
                };
                break;
            case '[UtilState] Add Email Status':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        is_payroll_processor_sent: action.is_payroll_processor_sent,
                        is_payroll_processor_linked: action.is_payroll_processor_linked,
                        is_bank_statement_sent: action.is_bank_statement_sent,
                        is_bank_statement_linked: action.is_bank_statement_linked,
                        bank_statement_hash: action.bank_statement_hash,
                        is_data_source_linked: action.is_data_source_linked,
                        is_data_source_sent: action.is_data_source_sent
                    }
                };
                break;
            case '[UtilState] Add Backend User Details':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        backendUserData: action.userData,
                        review: null
                    },
                };
                break;
            case '[UtilState] Set parsed PDF Data':
                state = {
                    ...state,
                    pdf_parse_app: {
                        ...state.pdf_parse_app,
                        parsedData: action.parsedData,
                        review: null
                    },
                };
                break;
            case '[UtilState] Set Is App Created using PDF':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        isPdfApp: action.isPdfApp
                    },
                };
                break;
            case '[UtilState] Add Skip State':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        skipStep: action.skipStep
                    },
                };
                break;
            case '[UtilState] Add Parent User':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        parentUser: action.parentUser
                    },
                };
                break;
            case '[UtilState] Add Plan Checkout Data':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        planCheckoutData: action.planCheckoutData
                    },
                };
                break;
            case '[UtilState] Add Plan Data For Payment':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        planData: action.planData
                    },
                };
                break;
            case '[UtilState] add Replicated App Data':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        replicatedAppData: action.replicatedAppData
                    },
                };
                break;
            case '[UtilState] Add Product Type':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        productType: action.productType
                    }
                };
                break;
            case '[UtilState] Set SBA Product Config':
                state = {
                    ...state,
                    app: {
                        ...state.app,
                        configurations: action.configurations
                    }
                };
                break;
            case '[UtilState] Add Additional App Data':
                 state = {
                   app: {
                     ...state.app,
                     additionalAppData: action.additionalAppData,
                   },
                 };
                 break;
            case '[UtilState] Add App Details':
            state = {
                 ...state,
                 app: {
                   ...state.app,
                   appData: action.appData,
                 },
                };
                break;    
                case '[App/CONFIG] Add Config':
        state = {
          app: {
            ...state.app,
            configurations: action.configurations,
          },
        };
        break;
            default:
                break;
        }
        return reducer(state, action);
    };
}